<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-row gap-4">
      <EditClientField
        id="woco_id"
        :modelValue="household.id_woco"
        @update:modelValue="household.id_woco = $event.target.value.trim()"
        label="ID (optioneel)"
      />
      <EditClientField
        id="hh_street"
        :modelValue="household.street"
        @update:modelValue="household.street = $event.target.value.trim()"
        label="Straat"
        :disabled="disableAddress"
        required
      />
      <EditClientField
        id="hh_house_number"
        :modelValue="household.house_number"
        @update:modelValue="household.house_number = $event.target.value.trim()"
        label="Huisnummer"
        :disabled="disableAddress"
        required
      />
      <EditClientField
        id="hh_postal_code"
        :modelValue="household.postal_code"
        @update:modelValue="household.postal_code = $event.target.value.trim()"
        label="Postcode"
        :disabled="disableAddress"
        required
      />
    </div>
    <div class="flex flex-row gap-4">
      <EditClientField
        id="hh_city"
        :modelValue="household.city"
        @update:modelValue="household.city = $event.target.value.trim()"
        label="Stad"
        :disabled="disableAddress"
        required
      />
      <EditClientField
        id="hh_country"
        :modelValue="household.country"
        @update:modelValue="household.country = $event.target.value.trim()"
        label="Land"
        :disabled="disableAddress"
        required
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EditClientField from './EditClientField.vue'

// note: we should remove the mutation of props in this component (BK Jun 23)

export default {
  name: 'EditClientHousehold',
  components: {
    EditClientField,
  },
  props: {
    household: Object,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['user']),
    disableAddress() {
      return this.household.id && this.user.permissions.change_address === false
    },
  },
}
</script>
