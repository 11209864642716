<template>
  <div class="interactions">
    <div class="container">
      <div class="row">
        <div class="col-2" style="margin-bottom: 20px">
          <select class="form-control bootstrap-select pointer" @change="onChange">
            <option value="Events" selected="selected">Events</option>
            <option value="Users">Users</option>
          </select>
        </div>
      </div>
      <div class="row">
        <!-- Events table -->
        <div v-if="selected === 'Events'" class="col-3 overview-table">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Context</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(interaction, index) in interactionOverview"
                :key="index"
                @click="() => onActionRowClick(interaction.event_type, interaction.event_context)"
                class="overview-table-row"
              >
                <td>{{ interaction.event_type }}</td>
                <td>{{ interaction.event_context }}</td>
                <td>{{ interaction.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- User table -->
        <div v-else-if="selected === 'Users'" class="col-3 overview-table">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">User</th>
                <th scope="col">Last interaction at</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="interaction in userOverview"
                :key="interaction.user"
                @click="() => onUserRowClick(interaction.user)"
                class="overview-table-row"
              >
                <td>{{ interaction.user }}</td>
                <td>{{ interaction.datetime | asDate }}</td>
                <td>{{ interaction.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="isLoading">Loading...</div>
        <div
          v-else-if="interactionDetails.length > 0 && selected === 'Events'"
          class="col-9 detailed-table"
        >
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Context</th>
                <th scope="col">User</th>
                <th scope="col">Date</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(interaction, index) in interactionDetails" :key="index">
                <td style="width: 60px">{{ interaction.event_type }}</td>
                <td style="width: 95px">{{ interaction.event_context }}</td>
                <td style="width: 140px">{{ interaction.user }}</td>
                <td style="width: 145px">{{ interaction.datetime | asDate }}</td>
                <td>{{ interaction.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-else-if="userDetails.length > 0 && selected === 'Users'"
          class="col-9 detailed-table"
        >
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  <select
                    class="bootstrap-select pointer"
                    style="border: none; font-weight: bold"
                    @change="onTypeChange"
                    :key="new Date().toLocaleString()"
                  >
                    <option value="Type" selected="selected">Type</option>
                    <option v-for="type in eventTypes" :key="type" :value="type">{{ type }}</option>
                  </select>
                </th>
                <th scope="col">Context</th>
                <th scope="col">User</th>
                <th scope="col">Date</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(interaction, index) in filteredUserDetails" :key="index">
                <td style="width: 60px">{{ interaction.event_type }}</td>
                <td style="width: 95px">{{ interaction.event_context }}</td>
                <td style="width: 140px">{{ interaction.user }}</td>
                <td style="width: 145px">{{ interaction.datetime | asDate }}</td>
                <td>{{ interaction.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>Click on a row to provide more information here.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Interactions',
  data() {
    return {
      interactionOverview: [],
      userOverview: [],
      interactionDetails: [],
      userDetails: [],
      filteredUserDetails: [],
      isLoading: false,
      selected: 'Events',
    }
  },
  computed: {
    eventTypes() {
      const types = []
      this.userDetails.forEach((event) => {
        if (!types.includes(event.event_type)) types.push(event.event_type)
      })
      return types
    },
  },
  methods: {
    filterUserDetails(filter) {
      if (filter === 'Type') {
        this.filteredUserDetails = this.userDetails
        return
      }
      this.filteredUserDetails = this.userDetails.filter((event) => event.event_type === filter)
    },
    async getUserOverview() {
      const response = await fetch('/api/user/interaction/users')
      const jsonResponse = await response.json()
      if (response.status === 200) {
        this.userOverview = jsonResponse.data.sort((a, b) => b.count - a.count)
      }
    },
    async getUserDetails(user) {
      this.isLoading = true
      const response = await fetch(`/api/user/interaction/users/${user}`)
      const jsonResponse = await response.json()
      if (response.status === 200) {
        this.userDetails = jsonResponse.data.reverse()
        this.filteredUserDetails = this.userDetails
      }
      this.isLoading = false
    },
    async getInteractionOverview() {
      const response = await fetch('/api/user/interaction')
      const jsonResponse = await response.json()
      if (response.status === 200) {
        this.interactionOverview = jsonResponse.data.sort((a, b) => b.count - a.count)
      }
    },
    async getInteractionDetails(type, context) {
      this.isLoading = true
      const response = await fetch(`/api/user/interaction/${type}/${context}`)
      const jsonResponse = await response.json()
      if (response.status === 200) {
        this.interactionDetails = jsonResponse.data.reverse()
      }
      this.isLoading = false
    },
    onActionRowClick(type, context) {
      this.getInteractionDetails(type, context)
    },
    onUserRowClick(user) {
      this.getUserDetails(user)
    },
    onChange({ target }) {
      this.selected = target.value
      this.userDetails = []
      this.interactionDetails = []
    },
    onTypeChange({ target }) {
      this.filterUserDetails(target.value)
    },
  },
  created() {
    this.getInteractionOverview()
  },
  watch: {
    selected() {
      if (this.selected === 'Events') this.getInteractionOverview()
      if (this.selected === 'Users') this.getUserOverview()
    },
  },
  filters: {
    asDate(t) {
      return new Date(t).toLocaleString()
    },
  },
}
</script>

<style lang="postcss">
.interactions {
  font-size: 10px;

  .overview-table-row {
    &:hover {
      background-color: rgb(235, 235, 235);
      cursor: pointer;
    }
  }
}
</style>
