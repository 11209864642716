<template>
  <div>
    <div v-if="isLoading" class="flex gap-5 items-center">
      <div
        class="border-gray-300 m-5 h-10 w-10 animate-spin rounded-full border-8 border-t-green-600"
      />
      Loading...
    </div>
    <div v-else-if="error || !location || location.status === 'error'">
      Something went wrong or case does not exist.
      {{ error }}
    </div>
    <div v-else>
      <div class="bg-white drop-shadow-xl rounded-b-lg p-3">
        <div class="font-bold tracking-wider text-lg text-gray-500 my-3">
          {{ location.address.street }} {{ location.address.house_number }}
        </div>
        <div
          class="rounded-lg inline p-1 text-white bg-blue-200"
          :class="{
            'bg-pink-200': location.status.overall.status === 'cancelled',
            'bg-emerald-200': location.status.overall.status === 'finished',
          }"
        >
          {{ translateStatus(location) }}
        </div>
        <div
          v-if="location.status.design.comment && location.status.design.status == 'cancelled'"
          class="mt-3"
        >
          <div class="text-sm">Reden legplan geannuleerd:</div>
          <div>{{ location.status.design.comment }}</div>
        </div>

        <div class="flex mt-2 gap-2" v-if="project">
          <span class="font-bold">Project</span>
          <span>
            <button class="underline" @click="$emit('filter', { projects: [project.id] })">
              {{ project.name }}
            </button>
          </span>
        </div>

        <div class="flex mt-2 gap-2" v-if="location.installation">
          <span class="font-bold">Aantal panelen</span>
          <span>
            {{ location.installation.panel.amount }}x {{ location.installation.panel.name }}
          </span>
        </div>

        <div class="flex mt-2 gap-2" v-if="location.installation">
          <span class="font-bold">Installatiedatum</span>
          <span>
            {{ formatDate(location.installation.appointment_date) }}
          </span>
        </div>

        <div v-if="location.eleenas?.length" class="flex mt-2 gap-2">
          <div class="font-bold">Eleena eerste productie</div>
          <div>{{ formatDate(location.eleenas[0].first_production_at) }}</div>
        </div>

        <div class="flex gap-2 flex-row justify-between mt-3">
          <a
            target="_blank"
            :href="location._links.asbuilt"
            v-if="location._links.asbuilt"
            class="bg-gray-500 rounded px-1 text-white flex items-center pointer"
          >
            <DocumentTextIcon size="20" />
            <div class="px-1">Asbuilt PDF</div>
          </a>
          <a
            target="_blank"
            :href="
              location._links.sales_contract_letter_pdf ||
              location._links.sales_contract_signrequest
            "
            v-if="
              location._links.sales_contract_letter_pdf ||
              location._links.sales_contract_signrequest
            "
            class="bg-gray-500 rounded px-1 text-white flex items-center pointer"
          >
            <DocumentTextIcon size="20" />
            <div class="px-1">Akkoordverklaring</div>
          </a>
        </div>
      </div>
    </div>

    <div v-if="tenants && tenants.length > 0" class="bg-white drop-shadow-xl rounded-b-lg p-3 mt-5">
      <div class="font-bold tracking-wider text-lg text-gray-500 my-3 flex">
        <span> Bewoners</span>
        <PencilIcon size="20" @click="editTenant(tenants[0])" class="ml-3 mt-1 pointer" />
      </div>

      <div class="flex justify-between items-center">
        <div>
          <div><span class="font-bold">Naam: </span>{{ tenants[0].name }}</div>
          <div><span class="font-bold">Email(s): </span>{{ tenants[0].email }}</div>
          <div><span class="font-bold">Telefoon nummer(s): </span>{{ tenants[0].phone }}</div>
        </div>
      </div>
      <button @click="editTenant()" class="underline text-blue-500 mt-3">Huurder vervangen</button>
    </div>

    <generic-modal
      v-if="showTenantModal"
      @close="showTenantModal = false"
      @submit="confirmAction"
      title="Bewonersgegevens bewerken"
    >
      <FormField label="Naam" v-model="editedTenant.name" />
      <FormField label="Email(s)" v-model="editedTenant.email" />
      <FormField label="Telefoon nummer(s)" v-model="editedTenant.phone" />
      Meerdere waarden kunnen met komma's worden opgeteld (0123456, 0165432)
    </generic-modal>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import fetchData from '@/util/fetch'
import { translateStatus } from '@/util/status'
import { PencilIcon, DocumentTextIcon } from '@vue-hero-icons/outline'
import FormField from '@/components/shared/FormField.vue'
import GenericModal from '@/components/shared/GenericModal.vue'
import { DateTime } from 'luxon'
import { useNotificationsStore } from '@/store/notifications'

const route = useRoute()
const queryClient = useQueryClient()
const id = computed(() => route.params?.id)
const showTenantModal = ref(false)
const editedTenant = ref({ name: '', email: '', phone: '', tenant_id: '' })

function formatDate(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)
}

const {
  data: location,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ['location', String(id.value)]),
  queryFn: async () => {
    try {
      const result = await fetchData(`/tickets/api/v1/locations/${id.value}`)
      if (result.status !== 'OK' || !result) {
        return
      }
      return result?.data
    } catch (error) {
      throw new Error('Failed to fetch location data')
    }
  },
  refetchOnWindowFocus: false,
})

const getProjects = () => fetchData('/config/api/v1/projects/?view=full')
const projectsQuery = useQuery({
  queryKey: ['projects'],
  queryFn: getProjects,
})

const project = computed(() =>
  projectsQuery.data.value?.data.find((prj) => prj.id === location.value.project_id)
)

const notifications = useNotificationsStore()

const fetchAsbuilt = async () => {
  try {
    console.log('fetching asbuilt data, for test Broekweg 95 2161XC')
    const result = await fetchData(
      `/tickets/api/v1/locations/${location.value.location_id}/asbuilt`
    )
    console.log(result.data)
    window.open(result.data.url, '_blank')
  } catch (error) {
    console.error(error)
    notifications.add(`Failed to fetch asbuilt data: ${error.message}`, 'error')
  }
}

const { data: tenants } = useQuery({
  queryKey: computed(() => ['tenants', String(id.value)]),
  queryFn: async () => {
    try {
      const result = await fetchData(`/tickets/api/v1/locations/${id.value}/tenants`)
      if (result.status !== 'OK' || !result) {
        return
      }
      return result?.data
    } catch (error) {
      throw new Error('Failed to fetch tenants data')
    }
  },
  refetchOnWindowFocus: false,
})

const editTenant = (tenant) => {
  editedTenant.value = tenant ? { ...tenant } : { name: '', email: '', phone: '' }
  showTenantModal.value = true
}

const updateTenant = async (tenant) => {
  console.log('updating tenant', tenant)
  const updateTenant = () =>
    fetchData(`/tickets/api/v1/tenants/${tenant.tenant_id}`, {
      method: 'PUT',
      body: JSON.stringify(tenant),
    })
  const createTenant = () =>
    fetchData(`/tickets/api/v1/locations/${id.value}/tenants`, {
      method: 'POST',
      body: JSON.stringify(tenant),
    })
  try {
    const response = tenant.tenant_id ? await updateTenant() : await createTenant()
    if (response.status === 'OK') {
      queryClient.invalidateQueries({ queryKey: ['tenants'] })
      notifications.add('Bewonersgegevens succesvol bijgewerkt', 'success')
    } else {
      throw new Error(response.message || 'Bijwerken van bewonersgegevens mislukt')
    }
  } catch (error) {
    notifications.add(`Fout bij het bijwerken van bewonersgegevens: ${error.message}`, 'error')
  }
}

const confirmAction = async () => {
  await updateTenant(editedTenant.value)
  showTenantModal.value = false
}
</script>
