import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '@/views/Home.vue'
import Address from '@/views/Address.vue'
import Dashboard from '@/views/Dashboard.vue'
import Locations from '@/views/Locations.vue'
import LoginView from '@/views/LoginView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import Forbidden from '@/views/Forbidden.vue'
import Interactions from '@/views/Interactions.vue'
import UploadExcel from '@/views/UploadExcel.vue'
import Import from '@/views/Import.vue'

import * as requests from '@/common/requests'
import * as auth from '@/common/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/gegevens',
    name: 'Gegevens',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/import',
    name: 'Import',
    component: Import,
    meta: { requiresAuth: true },
  },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
    meta: { requiresAuth: true },
  },
  {
    path: '/locations/:id',
    name: 'location-detail',
    component: Locations,
    meta: { requiresAuth: 'Locations' },
  },
  {
    path: '/address',
    name: 'Address',
    component: Address,
    meta: { requiresAuth: true },
  },
  {
    path: '/upload-excel',
    name: 'upload-excel',
    component: UploadExcel,
    meta: { requiresAuth: 'Gegevens' },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/interactions',
    name: 'Interactions',
    component: Interactions,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundView,
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach(async (to, from, next) => {
  await auth.verifyUserLoginStatus()
  if (to.meta.requiresAuth) {
    if (!store.state.isLoggedIn) {
      next({
        name: 'Login',
        query: {
          redirect: to.path,
        },
      })
    } else {
      try {
        const response = await requests.getScreenPermissions()
        console.log('Screen permissions: ', response)
        const screens = response.data
        const nextScreen = screens.find(
          (s) => s.name === to.name || s.name === String(to.meta.requiresAuth)
        )

        if (store.state.user.permissions[nextScreen.permission]) {
          next()
        } else {
          next({ name: 'Forbidden', query: { redirect: to.name } })
        }
      } catch (err) {
        console.log('Error when fetching screen data')
        console.error(err)
        next({ name: 'NotFound' })
      }
    }
  } else if (to.name === 'Login' && store.state.isLoggedIn) {
    next({ name: 'Dashboard' })
  } else {
    next()
  }
})

export default router
