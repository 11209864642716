import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { EventsPlugin } from './plugins'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.config.productionTip = false

const pinia = createPinia()

Vue.use(EventsPlugin)
Vue.use(VueQueryPlugin)
Vue.use(PiniaVuePlugin)

new Vue({
  router,
  store,
  pinia,
  render: (h) => h(App),
}).$mount('#app')
