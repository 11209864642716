<template>
  <tr class="m-2">
    <td class="align-middle flex flex-start">
      <input
        type="checkbox"
        :checked="selectedAddresses.includes(location.location_id)"
        @change="$emit('toggleSelection', location.location_id)"
        class="mx-2"
      />
    </td>
    <td class="px-2">
      {{ location.id_woco }}
    </td>
    <td class="px-2">
      <template>
        <router-link
          :to="{
            name: 'location-detail',
            params: { id: location.location_id },
          }"
          class="text-gray-800 hover:underline"
        >
          {{ location.address.street }} {{ location.address.house_number }}
          <span class="text-gray-400 text-sm"
            >{{ location.address.postal_code }} {{ location.address.city }}</span
          >
        </router-link>
      </template>
    </td>
    <td class="px-1 rounded-lg flex justify-center" :class="getStatusClass(location)">
      {{ translateStatus(location) }}
    </td>

    <notification-message :message="notification.message" :type="notification.type" />
  </tr>
</template>

<script setup>
import { ref, computed } from 'vue'
import fetchData from '@/util/fetch'
import { translateStatus } from '@/util/status'
import NotificationMessage from './NotificationMessage.vue'

const props = defineProps({
  location: {
    type: Object,
    required: true,
  },
  selectedAddresses: {
    type: Array,
    required: true,
  },
  lastSelectedLocation: {
    type: String,
    required: true,
  },
})

const notification = ref({ message: '', type: '' })

const getStatusClass = (location) => {
  const status = translateStatus(location)
  if (status.includes('geannuleerd')) {
    return 'bg-pink-200'
  } else if (status === 'afgerond') {
    return 'bg-emerald-200'
  } else {
    return 'bg-blue-200'
  }
}
</script>
