<template>
  <div>
    <Modal v-show="isFetching">
      <Spinner class="w-16 h-16" />
    </Modal>
    <section class="section">
      <div class="container-fluid">
        <Alert
          :alerts="alertMessages"
          :positionClass="alertPositionClass"
          @close="alertMessages = []"
        />
        <div class="flex justify-content-start items-center gap-2 mx-3">
          <DropDownGroup />
          <DropDownProject
            v-if="user.wocos.find((woco) => woco.name === selectedWoco) !== undefined"
          />
          <DropDownYear
            v-if="user.wocos.find((woco) => woco.name === selectedWoco) !== undefined"
          />
          <DropDown v-if="user.wocos.length > 1" />
          <Filters />
          <p style="padding-left: 15px; margin: auto; margin-left: 0px; font-weight: 700">
            Resultaten: {{ total }}
          </p>
        </div>
        <div class="flex justify-content-start items-center gap-2 m-3">
          <e-button color="green" @click="onClickCreate" v-if="user.permissions.create_data">
            Voeg client toe
          </e-button>
          <e-button
            color="gray"
            @click="$router.push({ name: 'upload-excel' })"
            v-if="user.permissions.create_data"
          >
            Upload Excel bestand
          </e-button>
          <e-button
            color="blue"
            @click="onClickExportExcel"
            v-if="user.permissions.view_screen_clients"
          >
            Exporteer als Excel
          </e-button>

          <e-button
            :loading="EnergieLeveringLoading"
            :disabled="EnergieLeveringLoading"
            v-if="user.permissions.download_docs"
            color="blue"
            @click="onClickExportEnergieLevering"
          >
            Exporteer Energie Levering
          </e-button>
        </div>
        <ClientTable
          ref="clienttable"
          v-if="selectedWoco && clients.length >= 0"
          :columns="columns"
          :clients="clients"
          :markClientForEdit="onClickEdit"
          :minFilterLength="minFilterLength"
          :onClickImage="onClickImage"
          :initialSelectedStatus="initialSelectedStatus"
          :hasHistory="true"
          :hasActions="true"
          :setAlertMessages="setAlertMessages"
          :setIsFetching="setIsFetching"
          :additionalWorkCatalog="additionalWorkCatalog"
          :serviceCatalog="serviceCatalog"
        />
        <Modal v-if="createClientExcel" :onClose="closeExcelInput">
          <ExcelInput
            :close="closeExcelInput"
            :updateClientsList="updateClientsList"
            :setAlertMessages="setAlertMessages"
          />
        </Modal>
        <Modal v-if="clientHandler" :onClose="clearClientHandler">
          <EditClientForm
            :handler="clientHandler"
            :alerts="formFeedback"
            @close="clearClientHandler"
            @save="onSaveClient"
          />
        </Modal>
        <Modal v-if="selectedImage" :onClose="closeImage">
          <ImageModal :imageUrl="selectedImage" :allImages="carouselImages" />
        </Modal>
      </div>
    </section>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Spinner from '@/components/Spinner.vue'
import Modal from '@/components/Modal.vue'
import Alert from '@/components/Alert.vue'
import Filters from '@/components/Filters.vue'
import DropDown from '@/components/DropDown.vue'
import DropDownYear from '@/components/DropDownYear.vue'
import DropDownProject from '@/components/DropDownProject.vue'
import DropDownGroup from '@/components/DropDownGroup.vue'
import EditClientForm from '@/components/client-edit/EditClientForm.vue'
import ImageModal from '@/components/ImageModal.vue'
import ClientTable from '@/components/ClientTable.vue'
import ExcelInput from '@/components/ExcelInput.vue'
import * as requests from '@/common/requests'
import ClientHandler from '@/common/ClientHandler'
import { convertArrayToObject } from '@/common/convert'
import EButton from '@/components/EButton.vue'

export default {
  name: 'Gegevens',
  components: {
    EButton,
    Spinner,
    Modal,
    Alert,
    EditClientForm,
    ImageModal,
    ClientTable,
    ExcelInput,
    Filters,
    DropDown,
    DropDownYear,
    DropDownProject,
    DropDownGroup,
  },
  data() {
    return {
      initialSelectedStatus: undefined,
      isFetching: false,
      clients: [],
      serviceCatalog: null,
      additionalWorkCatalog: null,
      alertMessages: [],
      clientHandler: null,
      // clientMarkedForEdit: null,
      // clientMarkedForCreate: null,
      EnergieLeveringLoading: false,
      formFeedback: [],
      minFilterLength: 2,
      selectedImage: null,
      carouselImages: [],
      alertPositionClass: null,
      createClientExcel: null,
      total: 0,
      columns: [
        {
          field: 'household.id_woco',
          name: 'ID',
          sortable: false,
          width: '70px',
          hide: true,
        },
        {
          field: 'household.address',
          name: 'Adres',
          sortable: true,
          width: '200px',
        },
        {
          field: 'household.postal_code',
          name: 'Postcode',
          sortable: false,
          width: '50px',
        },
        {
          field: 'household.city',
          name: 'Stad',
          sortable: false,
          width: '100px;',
        },
        {
          field: 'people.name',
          name: 'Personen',
          sortable: false,
          width: '150px;',
        },
        {
          field: 'email.address',
          name: 'E-mail',
          sortable: false,
          width: '160px',
        },
        {
          field: 'phones.number_text',
          name: 'Telefoon nummers',
          sortable: false,
          width: '140px',
        },
        {
          field: 'taskData',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState([
      'userEmail',
      'isLoggedIn',
      'selectedWoco',
      'selectedYear',
      'selectedProject',
      'selectedGroup',
      'user',
      'documentTemplates',
    ]),
  },
  watch: {
    selectedWoco() {
      this.getClientsForSelectedWoco()
      this.getDocumentTemplates()
    },
    selectedYear() {
      this.getClientsForSelectedWoco()
    },
    selectedProject() {
      this.getClientsForSelectedWoco()
    },
    selectedGroup() {
      this.getClientsForSelectedWoco()
    },
    clients: {
      deep: true,
      handler() {
        const containsClientWithWocoId = this.clients.some((client) => client.household.id_woco)
        const column = this.columns.find((c) => c.name === 'ID')
        if (containsClientWithWocoId && column.hide) {
          column.hide = false
        }
        if (!containsClientWithWocoId && !column.hide) {
          column.hide = true
        }
        if (this.clients.length === 0) {
          this.total = 0
        }
      },
    },
  },
  methods: {
    ...mapMutations(['setSelectedWoco', 'setDocumentTemplates']),
    async getDocumentTemplates() {
      const response = await requests.getDocumentTemplatesForWoco(this.selectedWoco)
      if (response.status === 'error') {
        console.warn('Failed to get document templates')
        this.setDocumentTemplates([])
        return
      }
      this.setDocumentTemplates(response.data)
    },

    async onSaveClient() {
      this.$events.emit('interaction', {
        type: 'submit',
        context: 'client_edit',
        value: `address=${this.clientHandler.address}`,
      })
      const postClients = [this.clientHandler.generatePostData()]
      const postData = {
        clients: postClients,
        woco: this.selectedWoco,
      }
      const response = await requests.postData('POST', 'api/client/operation/update/', postData)
      this.onUpdateClient(response)
    },

    objectToString(object) {
      let result = ''

      Object.entries(object).forEach(([key, value]) => {
        if (typeof value === 'string' || typeof value === 'number') {
          result += `${key}: ${value}, `
        } else if (Array.isArray(value)) {
          result += `${key}: ${value
            .map((v) => {
              if (typeof v === 'string' || typeof v === 'number') {
                return `${v}, `
              }
              return this.objectToString(v)
            })
            .join('')}`
        } else if (value && typeof value === 'object') {
          result += this.objectToString(value)
        }
      })

      return result
    },

    formatProducts(products) {
      const newProducts = []

      Object.entries(products).forEach(([type, value]) => {
        if (!value) return

        Object.entries(value).forEach((prod) => {
          const newProduct = {
            type,
          }

          const amount = prod[1].amount
          const serialNumbers = prod[1].serial_numbers
          const productType = prod[1].type || prod[1].description
          const power = prod[1].W || prod[1].wp

          if (productType) {
            newProduct.description = productType
          }
          if (amount) {
            newProduct.amount = amount
          }
          if (power) {
            newProduct.power = power
          }
          if (serialNumbers) {
            newProduct.serialNumbers = serialNumbers
          }

          newProducts.push(newProduct)
        })
      })

      return newProducts
    },

    async onClickExportExcel() {
      let lengthAdditionalWorks = 1
      const rows = this.$refs.clienttable.filteredClients.map((client) => {
        /* eslint-disable camelcase */

        const {
          orientation,
          gutter_height,
          house_side,
          shadow_loss,
          roof_orientation,
          roof_type,
          roof_angle,
        } = client.task_data.design.extra_fields || {}

        const images = client.task_data.design ? client.task_data.design.images || [] : []

        const panelConfiguration = images.find((image) => image.type === 'panel_configuration')
          ? images.find((image) => image.type === 'panel_configuration').url.replaceAll('?dl=1', '')
          : ''

        const streetView = images.find((image) => image.type === 'street_view')
          ? images.find((image) => image.type === 'street_view').url.replaceAll('?dl=1', '')
          : ''

        const appointmentDate = client.task_data.installation
          ? client.task_data.installation.appointment_date || ''
          : ''

        const designProducts =
          client.task_data.design && client.task_data.design.products
            ? client.task_data.design.products
            : []

        const designPanels = designProducts
          .filter((product) => product.type === 'panel')
          .map((product) => product.description || '')
          .join(', ')

        const designPanelsAmount = designProducts
          .filter((product) => product.type === 'panel')
          .reduce((acc, product) => acc + parseInt(product.amount, 0), 0)

        const designInverters = designProducts
          .filter((product) => product.type === 'inverter')
          .map((product) => product.description || '')
          .join(', ')

        const designInvertersAmount = designProducts
          .filter((product) => product.type === 'inverter')
          .reduce((acc, product) => acc + parseInt(product.amount, 0), 0)

        const designSmartmeter = designProducts
          .filter((product) => product.type === 'smartmeter')
          .map((product) => product.description || '')
          .join(', ')

        let installationProducts = []

        const services = []
        const additionalWorks = []

        const installationStatus = client.task_data.installation
          ? client.task_data.installation.status
          : undefined

        if (client.task_data.installation && client.task_data.installation.tickets) {
          Object.values(client.task_data.installation.tickets).forEach((ticket) => {
            if (
              !ticket.output ||
              (ticket.current_status !== 'approved' && ticket.current_status !== 'rejected')
            )
              return

            if (ticket.output.products) {
              installationProducts = [
                ...installationProducts,
                ...this.formatProducts(ticket.output.products),
              ]
            }

            if (ticket.output.services) {
              if (ticket.output.services.service) {
                services.push(this.serviceCatalog[ticket.output.services.service])
              }
              if (ticket.output.services.additional_work) {
                ticket.output.services.additional_work.forEach((additionalWork) => {
                  additionalWorks.push(this.additionalWorkCatalog[additionalWork])
                })
              }
            }
          })
        }

        if (additionalWorks.length > lengthAdditionalWorks) {
          lengthAdditionalWorks = additionalWorks.length
        }

        const installationPanels = installationProducts
          .filter((product) => product.type === 'panel')
          .map((product) => product.description || '')
          .join(', ')

        const installationPanelsAmount = installationProducts
          .filter((product) => product.type === 'panel')
          .reduce((acc, product) => acc + parseInt(product.amount, 0), 0)

        const installationInverters = installationProducts
          .filter((product) => product.type === 'inverter')
          .map((product) => product.description || '')
          .join(', ')

        const installationInvertersAmount = installationProducts
          .filter((product) => product.type === 'inverter')
          .reduce((acc, product) => acc + parseInt(product.amount, 0), 0)

        const installationSmartmeter = installationProducts
          .filter((product) => product.type === 'smartmeter')
          .map((product) => product.description || '')
          .join(', ')

        // const installationSmartmeterAmount = designProducts
        //   .filter((product) => product.type === 'smartmeter')
        //   .reduce((acc, product) => acc + parseInt(product.amount, 0), 0);

        const panelSerialNumbers = installationProducts
          .filter((product) => product.type === 'panel')
          .map((product) => product.serialNumbers)
          .join(', ')

        const inverterSerialNumbers = installationProducts
          .filter((product) => product.type === 'inverter')
          .map((product) => product.serialNumbers)
          .join(', ')

        return [
          client.household.id_woco,
          `${client.household.street} ${client.household.house_number}`,
          client.household.postal_code,
          client.household.city,
          client.people.map((person) => person.name).join(' '),
          client.email.address,
          client.phones.map((phone) => phone.number).join(' '),
          client.task_data.status_translated,
          client.task_data.task_status,
          appointmentDate,
          orientation,
          gutter_height,
          house_side,
          shadow_loss,
          roof_orientation,
          roof_type,
          roof_angle,
          panelConfiguration,
          streetView,
          designPanels,
          designPanelsAmount,
          designInverters,
          designInvertersAmount,
          designSmartmeter,
          installationPanels,
          installationPanelsAmount,
          installationInverters,
          installationInvertersAmount,
          installationSmartmeter,
          panelSerialNumbers,
          inverterSerialNumbers,
          services.join(', '),
          ...additionalWorks,
        ]
      })

      rows.unshift([
        'ID',
        'Adres',
        'Postcode',
        'Stad',
        'Personen',
        'E-mail',
        'Telefoon nummers',
        'Status',
        'Fase status',
        'Installatiedatum',
        'Oriëntatie',
        'Dak hoogte',
        'Huis kant',
        'Schaduwverlies',
        'Dak oriëntatie',
        'Daktype',
        'Dak hoek',
        'Legplan',
        'Street View',
        'Panelen (Ontwerp)',
        'Aantal panelen (Ontwerp)',
        'Omvormers (Ontwerp)',
        'Aantal omvormers (Ontwerp)',
        'Smartmeters (Ontwerp)',
        'Panelen (Installatie)',
        'Aantal panelen (Installatie)',
        'Omvormers (Installatie)',
        'Aantal omvormers (Installatie)',
        'Smartmeters (Installatie)',
        'Serienummers Panelen',
        'Serienummers Omvormers',
        'Services',
        ...Array.from(Array(lengthAdditionalWorks).keys()).map((n) => 'Meer werk'),
      ])

      // const csvContent = `data:text/csv;charset=utf-8,${rows.map((e) =>
      // e.join(',')).join('\n')}`;

      // const encodedUri = encodeURI(csvContent);
      // const link = document.createElement('a');

      // link.setAttribute('href', encodedUri);
      // link.setAttribute('download', `${this.selectedWoco}.csv`);
      // document.body.appendChild(link); // Required for FF

      // link.click();

      const data = {
        filename: this.selectedWoco,
        items: rows,
      }
      const response = await requests.postDataBlob('POST', 'api/excel/', data)

      if (response.status === 'error') {
        console.warn('Failed to download excel')
        return
      }

      const blob = response.blob
      const blobDownloadLink = window.URL.createObjectURL(blob)

      const anchor = document.createElement('a')
      anchor.href = blobDownloadLink
      anchor.download = `${this.selectedWoco}-${this.total}.xlsx`
      anchor.click()
      window.URL.revokeObjectURL(blobDownloadLink)
      anchor.remove()
    },

    async onClickExportEnergieLevering() {
      this.EnergieLeveringLoading = true

      const rows = this.$refs.clienttable.filteredClients.map((client) => {
        let products = []

        if (client.task_data.installation && client.task_data.installation.tickets) {
          Object.values(client.task_data.installation.tickets).forEach((ticket) => {
            if (!ticket.output || !ticket.output.products) return
            products = [...products, ...this.formatProducts(ticket.output.products)]
          })
        }
        // const design = client.task_data.design;
        const installation = client.task_data.installation
        // const products = design ? design.products || [] : [];
        const inverter = products.find((product) => product.type === 'inverter')
        const panel = products.find((product) => product.type === 'panel')
        const EANCode = client.household.ean
        const Netbeheerder = client.household.netbeheerder
        const EANNetbeheerder = client.household.ean_netbeheerder

        let inverterBrand = ''
        const possibleBrands = ['AP Systems', 'GoodWe']

        possibleBrands.forEach((brand) => {
          if (!inverter || !inverter.description) return
          if (inverter.description.includes(brand)) inverterBrand = brand
        })

        const inverterType = inverter ? inverter.description || '' : ''

        const inverterAmount = inverter ? inverter.amount || 0 : 0
        const inverterPower = inverter ? inverter.power || 0 : 0

        const totalInverterPower = (inverterAmount * inverterPower) / 1000

        const panelAmount = panel ? panel.amount || 0 : 0
        const panelPower = panel ? panel.power || 0 : 0

        const totalPanelPower = panelAmount * panelPower

        let installationDate = ''
        const rawDate = installation ? installation.appointment_date || false : false

        if (rawDate) {
          const date = new Date(rawDate)
          const day = date.getDate()
          const month = date.getMonth() + 1
          const year = date.getFullYear()

          installationDate = `${day}-${month}-${year}` // 1-1-2020
        }

        const houseNumber = client.household.house_number
        const postalCode = client.household.postal_code
        const email = client.email.address
        const phone = client.phones.length > 0 ? client.phones[0].number || '' : '' // only first number

        return [
          EANCode,
          EANCode ? EANCode.substr(EANCode.length - 4) : '',
          Netbeheerder,
          EANNetbeheerder,
          inverterBrand,
          inverterType,
          totalInverterPower,
          totalPanelPower,
          installationDate,
          houseNumber.replace(/\D/g, ''),
          houseNumber.replace(/[^\D]/g, ''),
          postalCode,
          email,
          phone,
        ]
      })

      rows.unshift([
        'EAN-code',
        'EAN-code (Laatste 4 cijfers)',
        'Netbeheerder',
        'EAN Netbeheerder',
        'Merk omvormer',
        'Type omvormer',
        'Totaal vermogen omvormers (kW)',
        'Totaal vermogen zonnepanelen (Wp)',
        'Installatie datum',
        'Huisnummer',
        'Toevoeging',
        'Postcode',
        'E-mail',
        'Telefoonnummer',
      ])

      const data = {
        filename: this.selectedWoco,
        items: rows,
      }

      const response = await requests.postDataBlob('POST', 'api/excel/', data)

      if (response.status === 'error') {
        console.warn('Failed to download excel')
        return
      }

      this.EnergieLeveringLoading = false

      const blob = response.blob
      const blobDownloadLink = window.URL.createObjectURL(blob)

      const anchor = document.createElement('a')
      anchor.href = blobDownloadLink
      anchor.download = `${this.selectedWoco}-${this.total}.xlsx`
      anchor.click()
      window.URL.revokeObjectURL(blobDownloadLink)
      anchor.remove()
    },

    async onClickCreate() {
      this.$events.emit('interaction', {
        type: 'open',
        context: 'client_creation',
        value: `woco=${this.selectedWoco}`,
      })

      try {
        const handler = await ClientHandler.init({
          woco: this.selectedWoco,
          onSave: this.onSaveClient,
        })

        this.clientHandler = handler
      } catch (err) {
        console.log('Handler initialization failed:', err.message)
      }
    },

    async onClickEdit(client) {
      this.$events.emit('interaction', {
        type: 'open',
        context: 'client_edit',
        value: `woco=${this.selectedWoco}&address=${client.household.address}`,
      })

      try {
        const handler = await ClientHandler.init({
          woco: this.selectedWoco,
          address: client.household.address,
          onSave: this.onSaveClient,
        })

        this.clientHandler = handler
      } catch (err) {
        console.error(err)
        console.log('Handler initialization failed:', err.message)
      }
    },

    async onClickImage(image, images = []) {
      this.carouselImages = []
      const imageResponse = await requests.getImage(image)
      if (imageResponse.data) {
        this.selectedImage = imageResponse.data
        if (images.length > 0) {
          images.forEach(async (arrayImage) => {
            const arrayImageResponse = await requests.getImage(arrayImage)
            this.carouselImages.push(arrayImageResponse.data)
          })
        } else {
          this.carouselImages.push(imageResponse.data)
        }
      }
    },

    onClickUploadExcel() {
      this.$events.emit('interaction', {
        type: 'open',
        context: 'excel_upload',
        value: `woco=${this.selectedWoco}`,
      })
      this.createClientExcel = true
    },

    async getClientsForSelectedWoco() {
      if (!this.selectedWoco) {
        this.clients = []
        return
      }

      if (!this.serviceCatalog || !this.additionalWorkCatalog) {
        const catalogResponse = await requests.getData('api/logistics/product_catalog/')

        if (catalogResponse.data && catalogResponse.data.data) {
          const items = catalogResponse.data.data
          const serviceCatalog = items?.find((catalog) => catalog.product_type === 'service')
          this.serviceCatalog = convertArrayToObject(
            serviceCatalog.products,
            'code',
            'technical_info'
          )

          const additionalWorkCatalog = items?.find(
            (catalog) => catalog.product_type === 'additional work'
          )
          this.additionalWorkCatalog = convertArrayToObject(
            additionalWorkCatalog.products,
            'code',
            'technical_info'
          )
          Object.keys(this.additionalWorkCatalog).forEach((key) => {
            if (
              this.additionalWorkCatalog[key] &&
              Array.isArray(this.additionalWorkCatalog[key].info)
            ) {
              this.additionalWorkCatalog[key] = this.additionalWorkCatalog[key].info[0]
            } else {
              this.additionalWorkCatalog[key] = '?'
            }
          })

          Object.keys(this.serviceCatalog).forEach((key) => {
            if (this.serviceCatalog[key] && Array.isArray(this.serviceCatalog[key].info)) {
              this.serviceCatalog[key] = this.serviceCatalog[key].info[0]
            } else {
              this.additionalWorkCatalog[key] = '?'
            }
          })
        }
      }
      this.isFetching = true
      this.$emit('toggleNavigation', !this.isFetching)
      const woco = this.user.wocos.find((w) => w.name === this.selectedWoco)
      const year =
        this.selectedYear && Object.keys(woco.available_years).includes(this.selectedYear)
          ? this.selectedYear
          : Object.keys(woco.available_years).pop()

      const selectedProject =
        this.selectedProject &&
        this.selectedProject !== 'Alle Projecten' &&
        woco.available_years[year].includes(this.selectedProject)
          ? this.selectedProject
          : null

      const selectedGroup =
        this.selectedGroup && this.selectedGroup !== 'Alle Groepen' ? this.selectedGroup : null

      const projects = selectedProject ? [selectedProject] : woco.available_years[year]

      const response = await requests.getClientsForWoco(this.selectedWoco, projects, selectedGroup)

      this.isFetching = false
      this.$emit('toggleNavigation', !this.isFetching)

      if (response.status === 'OK') {
        const { data } = response.json
        const clients = data
        this.clients = this.translateStatusForClients(clients)
        this.alertMessages = []
        if (response.response.status === 206) {
          this.alertMessages = [{ message: 'Laden van data gegevens mislukt', type: 'danger' }]
        }
        return
      }

      this.alertMessages = [{ message: response.message, type: 'danger' }]
      this.clients = []
    },

    translateStatusForClients(clients) {
      const statusTranslation = {
        installation: 'Installatie',
        inspection: 'Schouw',
        design: 'Ontwerp',
        sales: 'Akkoord',
      }
      return clients.map((client) => {
        const newClient = { ...client }

        if (client.task_data) {
          const statusHistory = Object.keys(client.task_data)
          if (statusHistory.includes('design')) {
            newClient.task_data.status = 'design'
            newClient.task_data.task_status = client.task_data.design.status
          }
          if (statusHistory.includes('sales')) {
            newClient.task_data.status = 'sales'
            newClient.task_data.task_status = client.task_data.sales.status
          }
          if (statusHistory.includes('inspection')) {
            newClient.task_data.status = 'inspection'
            newClient.task_data.task_status = client.task_data.inspection.status
          }
          if (statusHistory.includes('installation')) {
            newClient.task_data.status = 'installation'
            newClient.task_data.task_status = client.task_data.installation.status
          }
          newClient.task_data.status_translated = statusTranslation[newClient.task_data.status]
        }

        return newClient
      })
    },

    clearClientHandler() {
      this.formFeedback = []
      this.clientHandler = null
    },

    closeImage() {
      this.selectedImage = null
      this.carouselImages = []
    },

    closeExcelInput() {
      this.createClientExcel = null
    },

    async updateClientsList() {
      // TODO: request only new client instead of refetching the list
      await this.getClientsForSelectedWoco()
    },

    setAlertMessages(alerts) {
      this.alertMessages = alerts
    },

    setIsFetching(isFetching) {
      this.isFetching = isFetching
    },

    async onUpdateClient(response) {
      if (response.status === 'OK') {
        response.data.clients.forEach((client) => {
          const existingClientIndex = this.clients.findIndex(
            (c) => c.household.id === client.household.id
          )

          if (existingClientIndex >= 0) {
            // eslint-disable-next-line no-param-reassign
            client.task_data = this.clients[existingClientIndex].task_data

            this.$set(this.clients, existingClientIndex, client)
          } else {
            this.clients.push({ ...client, task_data: { design: {} } })
          }
        })
        this.setAlertMessages([{ message: 'Update succesvol', type: 'success' }])
        this.clearClientHandler()
        return
      } else {
        this.formFeedback = [{ message: response.message, type: 'danger' }]
        this.setAlertMessages([])
      }
    },

    // scrollListener() {
    //   this.alertPositionClass = window.scrollY > 100 ? 'position-fixed' : 'position-absolute';
    // },

    onFilteredClientsUpdate(value) {
      this.total = value
    },
  },
  created() {
    if (this.selectedWoco == null || !this.user.wocos.includes(this.selectedWoco)) {
      if (this.user.wocos.length === 1) {
        this.setSelectedWoco(this.user.wocos[0].name)
      }
    }
    if (this.selectedWoco !== null && this.selectedWoco !== undefined) {
      this.getClientsForSelectedWoco()
      this.getDocumentTemplates()
    }
  },
  mounted() {
    this.alertPositionClass = window.scrollY > 100 ? 'fixed' : 'absolute'
    // window.addEventListener('scroll', this.scrollListener);
    if (this.$router.currentRoute.query.status !== undefined) {
      this.initialSelectedStatus = this.$router.currentRoute.query.status
    }
    this.$root.$on('onFilteredClientsUpdate', this.onFilteredClientsUpdate)
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.scrollListener);
    this.$root.$off('onFilteredClientsUpdate', this.onFilteredClientsUpdate)
  },
}
</script>
