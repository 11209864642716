export const translateStatus = (location) => {
  const status = location.status.overall.status
  switch (status) {
    case 'finished':
      return 'afgerond'
    case 'design':
      return 'ontwerp'
    case 'sales':
      return 'werving'
    case 'inspection':
      return 'schouw'
    case 'installation':
      return 'installatie'
    case 'cancelled': {
      const { inspection, sales, design, installation } = location.status || {}

      if (inspection?.status === 'cancelled') {
        return 'schouw geannuleerd'
      } else if (sales?.status === 'cancelled') {
        return 'werving geannuleerd'
      } else if (design?.status === 'cancelled') {
        return 'ontwerp geannuleerd'
      } else if (installation?.status === 'cancelled') {
        return 'installatie geannuleerd'
      } else {
        return 'geannuleerd'
      }
    }
    default:
      return 'geen status'
  }
}
