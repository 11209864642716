<template>
  <div class="m-5">
    <div class="ml-6">
      <div v-if="errorMessage" class="bg-red-500 text-white p-4 rounded-lg shadow-lg">
        <p>{{ errorMessage }}</p>
      </div>
    </div>

    <div class="ml-6 search-container">
      <input
        type="text"
        id="addressInput"
        v-model="address"
        placeholder="Adres invoeren"
        class="border border-blue-700 w-auto p-3 rounded-md"
      />
      <button
        @click="fetchData"
        class="ml-6 p-3 rounded gap-2 flex items-center disabled:opacity-60 bg-blue-600 text-white hover:bg-blue-500"
      >
        Zoek adres
        <svg
          v-if="isLoading"
          class="animate-spin h-5 w-5 ml-2 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V2.83a1 1 0 011.41 0l2.83 2.83a1 1 0 010 1.41h0a1 1 0 01-1.41 0L13 5.41a1 1 0 00-1.41 0l-4.24 4.24a1 1 0 01-1.49-1.32l2.83-11a1 1 0 011.32-.49h0a1 1 0 01.49 1.32l-2.83 11a1 1 0 01-.74.74h0a1 1 0 01-1.32-.49L3.49 5.49A1 1 0 012.17 6.8h0a1 1 0 01.49-1.32L5.32 2.17a1 1 0 011.32.49h0a1 1 0 01-.49 1.32L4 6.83A8 8 0 014 12z"
          ></path>
        </svg>
      </button>
    </div>

    <section class="section">
      <div class="container-fluid">
        <Alert
          :alerts="alertMessages"
          :positionClass="alertPositionClass"
          @close="alertMessages = []"
        />
        <ClientTable
          ref="clienttable"
          v-if="clients.length >= 0"
          :columns="columns"
          :clients="clients"
          :markClientForEdit="onClickEdit"
          :minFilterLength="minFilterLength"
          :onClickImage="onClickImage"
          :initialSelectedStatus="initialSelectedStatus"
          :hasHistory="true"
          :showGroupsColumn="true"
          :hasActions="true"
          :setAlertMessages="setAlertMessages"
          :setIsFetching="setIsFetching"
          :additionalWorkCatalog="additionalWorkCatalog"
          :serviceCatalog="serviceCatalog"
        />
        <Modal v-if="clientHandler" :onClose="clearClientHandler">
          <EditClientForm
            :handler="clientHandler"
            :alerts="formFeedback"
            @close="clearClientHandler"
            @save="onSaveClient"
          />
        </Modal>
        <Modal v-if="selectedImage" :onClose="closeImage">
          <ImageModal :imageUrl="selectedImage" :allImages="carouselImages" />
        </Modal>
      </div>
    </section>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Modal from '@/components/Modal.vue'
import Alert from '@/components/Alert.vue'
import EditClientForm from '@/components/client-edit/EditClientForm.vue'
import ImageModal from '@/components/ImageModal.vue'
import ClientTable from '@/components/ClientTable.vue'
import * as requests from '@/common/requests'
import ClientHandler from '@/common/ClientHandler'

export default {
  name: 'SearchAddress',
  components: {
    Modal,
    Alert,
    EditClientForm,
    ImageModal,
    ClientTable,
  },
  data() {
    return {
      initialSelectedStatus: undefined,
      isFetching: false,
      isLoading: false,
      clients: [],
      serviceCatalog: null,
      additionalWorkCatalog: null,
      alertMessages: [],
      clientHandler: null,
      errorMessage: null,
      EnergieLeveringLoading: false,
      formFeedback: [],
      minFilterLength: 2,
      selectedImage: null,
      carouselImages: [],
      alertPositionClass: null,
      total: 0,
      columns: [
        {
          field: 'household.id_woco',
          name: 'ID',
          sortable: false,
          width: '70px',
          hide: true,
        },
        {
          field: 'household.address',
          name: 'Adres',
          sortable: true,
          width: '200px',
        },
        {
          field: 'household.postal_code',
          name: 'Postcode',
          sortable: false,
          width: '50px',
        },
        {
          field: 'household.city',
          name: 'Stad',
          sortable: false,
          width: '100px;',
        },
        {
          field: 'groups',
          name: 'Groups',
          sortable: false,
          width: '140px',
        },
        {
          field: 'people.name',
          name: 'Personen',
          sortable: false,
          width: '150px;',
        },
        {
          field: 'email.address',
          name: 'E-mail',
          sortable: false,
          width: '160px',
        },
        {
          field: 'phones.number_text',
          name: 'Telefoon nummers',
          sortable: false,
          width: '140px',
        },
        {
          field: 'taskData',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState([
      'userEmail',
      'isLoggedIn',
      'selectedWoco',
      'selectedYear',
      'selectedProject',
      'selectedGroup',
      'user',
      'documentTemplates',
    ]),
  },
  methods: {
    ...mapMutations(['setSelectedWoco', 'setDocumentTemplates']),

    async onSaveClient() {
      this.$events.emit('interaction', {
        type: 'submit',
        context: 'client_edit',
        value: `address=${this.clientHandler.address}`,
      })
      const postClients = [this.clientHandler.generatePostData()]
      const postData = {
        clients: postClients,
        woco: this.selectedWoco,
      }
      const response = await requests.postData('POST', 'api/client/operation/update/', postData)
      this.onUpdateClient(response)
    },

    objectToString(object) {
      let result = ''

      Object.entries(object).forEach(([key, value]) => {
        if (typeof value === 'string' || typeof value === 'number') {
          result += `${key}: ${value}, `
        } else if (Array.isArray(value)) {
          result += `${key}: ${value
            .map((v) => {
              if (typeof v === 'string' || typeof v === 'number') {
                return `${v}, `
              }
              return this.objectToString(v)
            })
            .join('')}`
        } else if (value && typeof value === 'object') {
          result += this.objectToString(value)
        }
      })

      return result
    },

    formatProducts(products) {
      const newProducts = []

      Object.entries(products).forEach(([type, value]) => {
        if (!value) return

        Object.entries(value).forEach((prod) => {
          const newProduct = {
            type,
          }

          const amount = prod[1].amount
          const serialNumbers = prod[1].serial_numbers
          const productType = prod[1].type || prod[1].description
          const power = prod[1].W || prod[1].wp

          if (productType) {
            newProduct.description = productType
          }
          if (amount) {
            newProduct.amount = amount
          }
          if (power) {
            newProduct.power = power
          }
          if (serialNumbers) {
            newProduct.serialNumbers = serialNumbers
          }

          newProducts.push(newProduct)
        })
      })

      return newProducts
    },

    async onClickCreate() {
      this.$events.emit('interaction', {
        type: 'open',
        context: 'client_creation',
        value: `woco=${this.selectedWoco}`,
      })

      try {
        const handler = await ClientHandler.init({
          woco: this.selectedWoco,
          onSave: this.onSaveClient,
        })

        this.clientHandler = handler
      } catch (err) {
        console.log('Handler initialization failed:', err.message)
      }
    },

    async onClickEdit(client) {
      this.$events.emit('interaction', {
        type: 'open',
        context: 'client_edit',
        value: `woco=${this.selectedWoco}&address=${client.household.address}`,
      })

      try {
        const handler = await ClientHandler.init({
          woco: this.selectedWoco,
          address: client.household.address,
          onSave: this.onSaveClient,
        })

        this.clientHandler = handler
      } catch (err) {
        console.error(err)
        console.log('Handler initialization failed:', err.message)
      }
    },

    onClickImage(image, images) {
      this.selectedImage = image
      this.carouselImages = images
    },

    async fetchData() {
      this.isLoading = true
      this.errorMessage = null // Reset the error message
      this.errorObject = null

      try {
        const response = await fetch(`/api/address/${encodeURIComponent(this.address)}`)

        if (response.status === 200) {
          this.isLoading = false
          // Process the successful response
          const { data } = await response.json()
          const clients = [data]
          this.clients = this.translateStatusForClients(clients)
          if (response.status === 206) {
            this.errorObject = { message: 'Laden van data gegevens mislukt', type: 'danger' }
          }
        } else {
          // Handle error response
          this.errorObject = {
            message: ' Er is een fout opgetreden bij het ophalen van de data.',
            type: 'danger',
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        this.errorObject = { message: 'Het adres is incorrect', type: 'danger' }
      }
      if (this.errorObject != null) this.errorMessage = this.errorObject.message
    },

    translateStatusForClients(clients) {
      const statusTranslation = {
        installation: 'Installatie',
        inspection: 'Schouw',
        design: 'Ontwerp',
        sales: 'Akkoord',
      }
      return clients[0].map((client) => {
        const newClient = { ...client }
        if (client.task_data) {
          const statusHistory = Object.keys(client.task_data)
          if (statusHistory.includes('design')) {
            newClient.task_data.status = 'design'
            newClient.task_data.task_status = client.task_data.design.status
          }
          if (statusHistory.includes('sales')) {
            newClient.task_data.status = 'sales'
            newClient.task_data.task_status = client.task_data.sales.status
          }
          if (statusHistory.includes('inspection')) {
            newClient.task_data.status = 'inspection'
            newClient.task_data.task_status = client.task_data.inspection.status
          }
          if (statusHistory.includes('installation')) {
            newClient.task_data.status = 'installation'
            newClient.task_data.task_status = client.task_data.installation.status
          }
          newClient.task_data.status_translated = statusTranslation[newClient.task_data.status]
        }

        return newClient
      })
    },
    clearClientHandler() {
      this.formFeedback = []
      this.clientHandler = null
    },

    closeImage() {
      this.selectedImage = null
      this.carouselImages = []
    },

    setAlertMessages(alerts) {
      this.alertMessages = alerts
    },

    setIsFetching(isFetching) {
      this.isFetching = isFetching
    },

    async onUpdateClient(response) {
      if (response.status === 'OK') {
        response.data.clients.forEach((client) => {
          const existingClientIndex = this.clients.findIndex(
            (c) => c.household.id === client.household.id
          )

          if (existingClientIndex >= 0) {
            // eslint-disable-next-line no-param-reassign
            client.tasks = this.clients[existingClientIndex].tasks

            this.$set(this.clients, existingClientIndex, client)
          } else {
            this.clients.push({ ...client, tasks: { design: {} } })
          }
        })
        this.setAlertMessages([{ message: 'Update succesvol', type: 'success' }])
        this.clearClientHandler()
        return
      } else {
        this.formFeedback = [{ message: response.message, type: 'danger' }]
        this.setAlertMessages([])
      }
    },

    onFilteredClientsUpdate(value) {
      this.total = value
    },
  },
  created() {
    if (this.selectedWoco == null || !this.user.wocos.includes(this.selectedWoco)) {
      if (this.user.wocos.length === 1) {
        this.setSelectedWoco(this.user.wocos[0].name)
      }
    }
  },
  mounted() {
    this.alertPositionClass = window.scrollY > 100 ? 'fixed' : 'absolute'
    // window.addEventListener('scroll', this.scrollListener);
    if (this.$router.currentRoute.query.status !== undefined) {
      this.initialSelectedStatus = this.$router.currentRoute.query.status
    }
    this.$root.$on('onFilteredClientsUpdate', this.onFilteredClientsUpdate)
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.scrollListener);
    this.$root.$off('onFilteredClientsUpdate', this.onFilteredClientsUpdate)
  },
}
</script>
<style scoped>
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
}

.search-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #0056b3;
}
</style>
