<template>
  <div class="page page-forbidden">
    <section class="section">
      <div class="container">
        <h1 v-if="redirectedFromScreen" class="text-center">
          Toegang geweigerd voor pagina {{ redirectedFromScreen }}
        </h1>
        <h1 v-else class="text-center">Toegang geweigerd</h1>
        <p class="text-center">Uw gebruiker heeft geen toegang om de pagina te bezichtigen.</p>
        <p class="text-center">
          Als u vermoed dat dit een fout is, neem dan contact op met de beheerder (<span
            class="text-primary"
            >it@ecorus.com</span
          >) om toegang te krijgen.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Forbidden',
  data() {
    return {
      redirectedFromScreen: this.$route.query.redirect || null,
    }
  },
  watch: {
    $route() {
      this.redirectedFromScreen = this.$route.query.redirect || null
    },
  },
  mounted() {
    this.$events.emit('interaction', {
      type: 'load',
      context: 'screen',
      value: 'forbidden',
    })
  },
}
</script>
