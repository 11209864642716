<template>
  <div class="flex flex-col">
    <!-- Previous stages -->
    <div class="popover-placeholder" v-if="showStatusDropdown">
      <!-- @blur="(e) => onBlur(e)" -->
      <Popover :offset="{ y: 15 }" :align="{ x: 'center' }" containerClass="wrapper">
        <div class="flex gap-1 flex-col">
          <template v-for="stage in ['installation', 'inspection', 'sales', 'design']">
            <div
              :key="stage"
              v-if="Object.keys(taskData).includes(stage)"
              :class="
                'rounded cursor-pointer w-32 text-sm p-2 ' +
                `bg-${statusColors[statusTranslation[stage]]}-300 hover:bg-${statusColors[statusTranslation[stage]]}-200` +
                `${selectedStatus === stage ? ' font-bold ' : ''}`
              "
              @click="() => selectHistory(stage)"
            >
              {{ statusTranslation[stage] }}
            </div>
          </template>
        </div>
      </Popover>
    </div>
    <div
      v-if="taskData.status !== ''"
      :class="
        'rounded flex flex-row p-1 cursor-pointer ' +
        `bg-${
          statusColors[
            selectedStatusHistory === undefined
              ? taskData.status_translated
              : statusTranslation[selectedStatusHistory]
          ]
        }-300`
      "
      ref="toggleButton"
      @click="() => onClick({ target: $refs.toggleButton })"
    >
      <div>
        <i v-if="shouldShowSelector" class="fas fa-caret-down"></i>
      </div>

      <div
        class="status-dropdown-inner"
        v-html="
          selectedStatusHistory === undefined
            ? taskData.status_translated
            : statusTranslation[selectedStatusHistory]
        "
      />
    </div>
  </div>
</template>

<script>
import Popover from '@/components/Popover.vue'

export default {
  name: 'StatusDropdown',
  props: {
    onSelectStatusHistory: Function,
    taskData: Object,
    statusColors: Object,
    selectedStatusHistory: String,
  },
  components: {
    Popover,
  },
  data() {
    return {
      showStatusDropdown: false,
      statusTranslation: {
        installation: 'Installatie',
        inspection: 'Schouw',
        design: 'Ontwerp',
        sales: 'Akkoord',
      },
      selectedStatus: undefined,
      justBlurred: false,
    }
  },
  watch: {
    selectedStatusHistory(value) {
      this.selectHistory(value)
    },
  },
  computed: {
    shouldShowSelector() {
      return (
        Object.keys(this.taskData).filter((key) => !['status', 'status_translated'].includes(key))
          .length > 1
      )
    },
  },
  methods: {
    selectHistory(status) {
      this.selectedStatus = status
      this.onSelectStatusHistory(this.selectedStatus)
      this.clearPopover()
    },
    onClick({ target }) {
      if (target === this.$refs.toggleButton && !this.showStatusDropdown) {
        this.showStatusDropdown = true
        this.$events.on('scroll-start', this.clearPopover)
        setTimeout(() => document.addEventListener('click', this.onClick), 0)
        return
      }
      this.clearPopover()
    },
    clearPopover() {
      this.showStatusDropdown = false
      document.removeEventListener('click', this.onClick)
      this.$events.off('scroll-start', this.clearPopover)
    },
  },
  beforeDestroy() {
    this.clearPopover()
  },
}
</script>

<style>
.status-history-popover .badge-history {
  display: flex;
  padding: 0.2rem;
  font-size: 0.7rem;
  margin-bottom: 0.1rem;
  cursor: pointer;
}

.status-history-popover .badge-history:hover {
  filter: brightness(1.05);
  color: white;
}

.status-dropdown {
  display: flex;
  margin: 0 0.25rem;
  cursor: pointer;
}

.status-dropdown-inner {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.status-dropdown-inner.history {
  padding: 0.2rem 2rem;
  font-size: 0.7rem;
}

.status-caret {
  cursor: pointer;
}

.popover-placeholder {
  position: relative;
}

.popover-placeholder .popover {
  /* top: 1rem; */
  /* left: -1.4rem; */
}
</style>
