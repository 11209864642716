<template>
  <div class="p-4 bg-gray-100 rounded">
    <form v-if="handler.client" class="was-validated" @submit.prevent="$emit('save')">
      <div class="mb-3 bg-gray-200 p-3 rounded">
        <h3 class="text-xl font-bold">Edit Client</h3>
      </div>
      <div class="flex flex-col gap-3">
        <div v-if="alerts && alerts.length" class="container flex justify-content-end">
          <Alert :alerts="alerts" />
        </div>
        <edit-client-household :household="handler.client.household" />
        <div class="container">
          <button
            type="button"
            class="rounded bg-gray-400 text-white p-2"
            @click="() => handler.addPerson()"
          >
            Voeg persoon toe
          </button>
        </div>
        <edit-client-person
          v-for="(person, idx) in handler.client.people"
          :key="`person-${idx}`"
          :person="person"
          :handler="handler"
        />
      </div>
      <div class="mt-3">
        <div class="bg-gray-200 p-3 rounded-b flex justify-end">
          <e-button type="button" @click="$emit('close')" color="gray">Annuleren</e-button>
          <e-button color="green" type="submit">Opslaan</e-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue'
import EditClientHousehold from './EditClientHousehold.vue'
import EditClientPerson from './EditClientPerson.vue'
import EButton from '@/components/EButton.vue'

export default {
  name: 'EditClientForm',
  emits: ['save'],
  components: {
    EditClientHousehold,
    EditClientPerson,
    Alert,
    EButton,
  },
  props: {
    handler: Object,
    alerts: Array,
  },
}
</script>
