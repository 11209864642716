<template>
  <div class="page page-404">
    <section class="section">
      <div class="container">
        <h1 class="text-center">404 - Not Found</h1>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  mounted() {
    this.$events.emit('interaction', {
      type: 'load',
      context: 'screen',
      value: '404',
    })
  },
}
</script>
