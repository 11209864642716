<template>
  <div class="page page-dashboard">
    <section class="section">
      <div class="flex justify-content-start items-center gap-2 mx-3">
        <DropDownGroup />
        <DropDownProject
          v-if="user.wocos.find((woco) => woco.name === selectedWoco) !== undefined"
        />
        <DropDownYear v-if="user.wocos.find((woco) => woco.name === selectedWoco) !== undefined" />
        <DropDown v-if="user.wocos.length > 1" />
      </div>
      <div class="">
        <div class="gap-2 mt-5 flex flex-col items-center" v-if="loading">
          <div>Dashboard laden...</div>
          <Spinner class="w-16 h-16" />
        </div>
        <div v-else-if="KPIsNotEmpty" class="kpi-container">
          <BigKPISection
            v-for="[type, KPI] in Object.entries(KPIs)"
            :key="type"
            :type="type"
            :color="colors[type]"
            :sectionColor="sectionColors[type]"
            :bigKPI="KPI.big"
            :smallKPIs="KPI.small"
          />
        </div>
        <div v-else class="kpi-container">
          <span>Geen gegevens gevonden voor dit jaar</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import BigKPISection from '@/components/BigKPISection.vue'
import Spinner from '@/components/Spinner.vue'
import DropDown from '@/components/DropDown.vue'
import DropDownYear from '@/components/DropDownYear.vue'
import DropDownProject from '@/components/DropDownProject.vue'
import DropDownGroup from '@/components/DropDownGroup.vue'
import * as requests from '@/common/requests'

export default {
  name: 'Dashboard',
  components: {
    BigKPISection,
    Spinner,
    DropDown,
    DropDownYear,
    DropDownProject,
    DropDownGroup,
  },
  data() {
    return {
      KPIs: {},
      colors: {
        installation: '#486f14',
        design: '#f99a18',
        sales: '#17a2b8',
      },
      sectionColors: {
        installation: '#d8e2ca',
        design: '#ffeeba',
        sales: '#e3f5fb',
      },
      loading: true,
      error: false,
    }
  },
  computed: {
    ...mapState([
      'userEmail',
      'isLoggedIn',
      'selectedWoco',
      'selectedYear',
      'selectedProject',
      'selectedGroup',
      'availableGroups',
      'user',
      'batchFilter2021',
    ]),
    KPIsNotEmpty() {
      return Object.values(this.KPIs).some((kpi) => kpi.big)
    },
  },
  watch: {
    selectedWoco(woco) {
      if (woco !== null) {
        this.getKPIs()
      }
    },
    batchFilter2021() {
      if (this.selectedWoco !== null) {
        this.getKPIs()
      }
    },
    selectedYear() {
      if (this.selectedWoco !== null) {
        this.getKPIs()
      }
    },
    selectedProject() {
      if (this.selectedWoco !== null) {
        this.getKPIs()
      }
    },
    selectedGroup() {
      if (this.selectedWoco !== null) {
        this.getKPIs()
      }
    },
  },
  methods: {
    ...mapMutations(['setSelectedWoco']),
    async getKPIs() {
      this.loading = true
      const woco = this.user.wocos.find((w) => w.name === this.selectedWoco)
      const year =
        this.selectedYear && Object.keys(woco.available_years).includes(this.selectedYear)
          ? this.selectedYear
          : Object.keys(woco.available_years).pop()

      const selectedProject =
        this.selectedProject &&
        this.selectedProject !== 'Alle projecten' &&
        woco.available_years[year] &&
        woco.available_years[year].includes(this.selectedProject)
          ? this.selectedProject
          : null

      let groups = []

      let projects = selectedProject ? [selectedProject] : woco.available_years[year]

      if (
        this.selectedGroup &&
        this.selectedGroup !== 'Alle Groepen' &&
        this.availableGroups.map((group) => group.name).includes(this.selectedGroup)
      ) {
        groups = [this.selectedGroup]
        projects = []
      }

      const dashboardInfo = await requests.getDashboardInfo(this.selectedWoco, projects, groups)

      if (dashboardInfo.status === 'error') {
        this.loading = false
        this.error = true
        return
      }

      let dashboardInfoData
      // Special case KW
      // if (['Kennemer Wonen'].includes(this.selectedWoco)) {
      //   dashboardInfoData = (
      //     this.selectedYear === '2020'
      //       ? dashboardInfo.data.default
      //       : dashboardInfo.data[this.selectedYear]
      //   );
      // }
      if (dashboardInfoData === undefined) {
        dashboardInfoData = dashboardInfo.data
      }

      this.KPIs = {}
      // Design
      this.KPIs.design = {
        big: {
          name: 'legplan gereed',
          value: dashboardInfoData.design.approved,
          percentage: (dashboardInfoData.design.approved / dashboardInfoData.design.total) * 100.0,
        },
        small: [
          {
            name: 'aangeleverde woningen',
            value: dashboardInfoData.design.total,
            color: 'lightgray',
            percentage: 100,
          },
          {
            name: 'in proces',
            value: dashboardInfoData.design.ongoing,
            color: 'gold',
            percentage: (dashboardInfoData.design.ongoing / dashboardInfoData.design.total) * 100.0,
          },
          {
            name: 'geannuleerd',
            value: dashboardInfoData.design.cancelled,
            color: 'indianred',
            percentage:
              (dashboardInfoData.design.cancelled / dashboardInfoData.design.total) * 100.0,
          },
        ],
      }

      // Only fill sales if the woco has sales in its configuration
      if (dashboardInfoData.sales !== null) {
        this.KPIs.sales = {
          big: {
            name: 'akkoord',
            value: dashboardInfoData.sales.approved,
            percentage: (dashboardInfoData.sales.approved / dashboardInfoData.sales.total) * 100.0,
          },
          small: [
            {
              name: 'interesse',
              value: dashboardInfoData.sales.finished,
              color: 'darkseagreen',
              percentage:
                (dashboardInfoData.sales.finished / dashboardInfoData.sales.total) * 100.0,
            },
            {
              name: 'nog niet bereikt',
              value: dashboardInfoData.sales.ongoing,
              color: 'gold',
              percentage: (dashboardInfoData.sales.ongoing / dashboardInfoData.sales.total) * 100.0,
            },
            {
              name: 'afgemeld',
              value: dashboardInfoData.sales.cancelled,
              color: 'indianred',
              percentage:
                (dashboardInfoData.sales.cancelled / dashboardInfoData.sales.total) * 100.0,
            },
          ],
        }
      } else {
        // Mock sales BigKPISection
        this.KPIs.sales = {
          big: null,
          small: [],
        }
      }

      const build =
        dashboardInfoData.installation.finished +
        dashboardInfoData.installation.rejected +
        dashboardInfoData.installation.approved

      // Installation
      this.KPIs.installation = {
        big: {
          name: 'geïnstalleerd',
          value: build,
          percentage: (build / dashboardInfoData.installation.total) * 100.0,
        },
        small: [
          {
            name: 'goedgekeurd',
            value: dashboardInfoData.installation.approved,
            color: 'darkseagreen',
            percentage:
              (dashboardInfoData.installation.approved / dashboardInfoData.installation.total) *
              100.0,
          },
          {
            name: 'gepland',
            value: dashboardInfoData.installation.ongoing,
            color: 'skyblue',
            percentage:
              (dashboardInfoData.installation.ongoing / dashboardInfoData.installation.total) *
              100.0,
          },
          {
            name: 'te plannen',
            value: dashboardInfoData.installation.created,
            color: 'gold',
            percentage:
              (dashboardInfoData.installation.created / dashboardInfoData.installation.total) *
              100.0,
          },
          {
            name: 'geannuleerd',
            value: dashboardInfoData.installation.cancelled,
            color: 'indianred',
            percentage:
              (dashboardInfoData.installation.cancelled / dashboardInfoData.installation.total) *
              100.0,
          },
          {
            name: 'afgekeurd',
            value: dashboardInfoData.installation.rejected,
            color: 'indianred',
            percentage:
              (dashboardInfoData.installation.rejected / dashboardInfoData.installation.total) *
              100.0,
          },
        ],
      }
      this.loading = false
    },
  },
  created() {
    if (
      this.user.wocos.length &&
      (this.selectedWoco === null ||
        !this.user.wocos.some((woco) => woco.name === this.selectedWoco))
    ) {
      this.setSelectedWoco(this.user.wocos[0].name)
    }
    if (this.selectedWoco !== null && this.selectedWoco !== undefined) {
      this.getKPIs()
    }
  },
  mounted() {
    this.$events.emit('interaction', {
      type: 'load',
      context: 'screen',
      value: 'dashboard',
    })
  },
}
</script>

<style>
.kpi-container {
  display: flex;
  justify-content: space-around;
}
</style>
