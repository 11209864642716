export const escapeRegExpChars = (str) => str.replace(/[()$^[\]?+*.]/g, '\\$&')

export function isDeepEqual(a, b, treatFunctionAsEqual = false) {
  if (a === b) return true // pointer, boolean, primitive, null
  if (a === null || b === null) return false // null counts as type object, check it early

  if (typeof a !== typeof b) return false
  if (typeof a === 'function') return treatFunctionAsEqual
  // typeof NaN -> number, NaN === NaN -> false, check specifically for NaN
  if (Number.isNaN(a) && Number.isNaN(b)) return true
  if (Number.isNaN(a) || Number.isNaN(b)) return false
  // non-matching primitives
  if (a !== b && typeof a !== 'object') return false

  // Compare various object types
  const objectTypes = [Map, WeakMap, Set, Array]
  const isNotSameType = objectTypes.some((obj) => a instanceof obj !== b instanceof obj)
  if (isNotSameType) return false

  if (a instanceof Array) {
    if (a.length !== b.length) return false

    for (let idx = 0; idx < a.length; idx += 1) {
      if (!isDeepEqual(a[idx], b[idx])) return false
    }
    return true
  }
  if (a instanceof Set) {
    if (a.size !== b.size) return false
    if (new Set([...a, ...b]).size !== a.size) return false
    return true
  }
  if (a instanceof Map) {
    if (a.size !== b.size) return false
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of a) {
      if (!b.has(key) || !isDeepEqual(value, b.get(key))) return false
    }
    return true
  }
  if (a instanceof WeakMap) {
    console.log('WeakMap found - returning false')
    return false
  }
  if (a instanceof Object) {
    return isDeepEqual(Object.entries(a), Object.entries(b))
  }
  // Should never get here, if it does it means something is missing
  console.warn('Failed to catch comparison condition:', a, b)
  return false
}
