<template>
  <div class="small-kpi" :style="`border: 1px solid ${kpi.color};`">
    <div class="small-kpi-box" :style="`background-color: ${kpi.color};`">
      {{ kpi.value }}
    </div>
    <div class="small-kpi-text">
      {{ kpi.name }}
    </div>
    <div
      class="small-kpi-box"
      v-if="kpi.percentage !== undefined"
      :style="`background-color: ${kpi.color};`"
    >
      {{ isNaN(kpi.percentage) ? '0.0' : kpi.percentage.toFixed(1) }}%
    </div>
  </div>
</template>

<script>
export default {
  props: {
    kpi: Object,
  },
}
</script>

<style>
.small-kpi {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  margin: 0.25rem;
  background-color: #ffffff4d;
  transition: all 0.15s ease-out;
  cursor: pointer;
}

.small-kpi-box {
  display: inline;
  text-align: center;
  width: 90px;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 4px;
  box-shadow:
    0 1em 2em -0.25em rgba(10, 10, 10, 0.25),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  font-size: 1.25rem;
}

.small-kpi:hover {
  transform: translateY(-2px) scale(1.005) translateZ(0);
  cursor: default;
  /*box-shadow: 0 2em 4em -0.5em rgba(10,10,10,.25), 0 0 0 2px rgba(10,10,10,.02);*/
}

.small-kpi-text {
  display: flex;
  flex-grow: 1;
  font-size: 1.25rem;
  justify-content: center;
}
</style>
