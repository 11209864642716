<template>
  <div
    :class="`big-kpi-section ${bigKPI === null ? 'big-kpi-hidden' : ''}`"
    :style="`background-color: ${sectionColor}`"
  >
    <BigKPI v-if="bigKPI !== null" :kpi="bigKPI" :type="type" :color="color" />
    <SmallKPI v-for="smallKPI in smallKPIs" :key="smallKPI.name" :kpi="smallKPI" />
  </div>
</template>

<script>
import BigKPI from '@/components/BigKPI.vue'
import SmallKPI from '@/components/SmallKPI.vue'

export default {
  components: {
    SmallKPI,
    BigKPI,
  },
  props: {
    bigKPI: Object,
    smallKPIs: Array,
    type: String,
    color: String,
    sectionColor: String,
  },
}
</script>

<style>
.big-kpi-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 4px;
}

.big-kpi-hidden {
  display: none;
}
</style>
