export function getCookie(name) {
  // Get name followed by anything except a semicolon
  var cookiestring = RegExp(name + '=[^;]+').exec(document.cookie)
  // Return everything after the equal sign, or an empty string if the cookie name not found
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : '')
}

export default async function fetchData(url, options = {}) {
  const headers = {
    Authorization: `Bearer ${getCookie('access_token')}`,
    'Content-Type': 'application/json',
    ...(options.headers || {}),
  }
  const result = await fetch(url, { ...options, headers })
  if (result.ok) {
    return result.json()
  }
  if (result.status === 401) {
    actions.auth.commitToken('')
    actions.auth.commitPermissions(null)
    actions.common.commitStatus('unauthorized')

    throw new Error('401 Unauthorized response from API')
  }
  if (result.headers.get('content-type') === 'application/json') {
    throw await result.json()
  }

  console.error('Fetch error', result)
  throw new Error('Invalid response from API')
}
