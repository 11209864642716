<template>
  <carousel
    :navigationEnabled="true"
    :scrollPerPage="true"
    :perPageCustom="[
      [480, 1],
      [768, 1],
    ]"
  >
    <slide v-for="(img, i) in images" :key="i">
      <img :src="img" style="width: 300px; max-width: 100%; display: inline" />
    </slide>
  </carousel>
</template>

<style>
.VueCarousel-slide {
  position: relative;
  color: #fff;
  font-family: Arial;
  font-size: 24px;
  text-align: center;
}
</style>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'ImageModal',
  props: {
    imageUrl: String,
    allImages: Array,
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      images: this.allImages,
    }
  },
}
</script>
