import { isDeepEqual } from '@/common/util'
import * as requests from '@/common/requests'

export default class ClientHandler {
  constructor(config) {
    this.initialOperations = []
    this.woco = config.woco
    this.onSave = config.onSave
    this.sortWeight = {
      household: 3,
      person: 2,
      email: 1,
      phone: 1,
    }

    if (config.client) {
      this.client = config.client
    } else if (!config.address) {
      this.client = ClientHandler.createNewClientObject()
    }
    if (this.client) {
      this.clientCopy = this.deepCopy(this.client)
    }
  }

  get address() {
    if (!this.client) return null
    const household = this.client.household
    return `${household.street} ${household.house_number} ${household.postal_code}`
  }

  static async init(config) {
    console.log('init ClientHandler', config)

    const handler = new ClientHandler(config)

    if (!config.client && config.address) {
      const response = await requests.getData(
        `/api/woco/${config.woco}/clients/details/${config.address}/`
      )
      if (response.status === 'OK') {
        handler.client = response.data
        handler.existingEntities = handler.storeOriginalEntityValues(handler.client)
      } else {
        throw new Error('Failed to fetch client data')
      }
    }

    handler.clientCopy = handler.deepCopy(handler.client)

    return handler
  }

  reset() {
    this.client = this.deepCopy(this.clientCopy)
  }

  save() {
    this.clientCopy = this.deepCopy(this.client)
  }

  addPerson() {
    this.client.people.push({
      name: '',
      emails: [],
      phones: [],
    })
  }

  addEmail(target) {
    const person = this.client.people.find((p) => p === target)
    person.emails.push({ address: '' })
  }

  addPhone(target) {
    const person = this.client.people.find((p) => p === target)
    person.phones.push({ number_text: '' })
  }

  removePerson(person) {
    this.client.people = this.client.people.filter((p) => p !== person)
    if (person.id) {
      this.initialOperations.push({
        type: 'remove',
        context: 'person',
        data: { ...person, person_id: person.id },
      })
    }
  }

  removePhone(target, phone) {
    const person = this.client.people.find((p) => p === target)
    person.phones = person.phones.filter((p) => p !== phone)
    if (phone.id && person.id) {
      this.initialOperations.push({
        type: 'remove',
        context: 'phone',
        data: { ...phone, person_name: person.name, person_id: person.id },
      })
    }
  }

  removeEmail(target, email) {
    const person = this.client.people.find((p) => p === target)
    person.emails = person.emails.filter((e) => e !== email)
    if (email.id && person.id) {
      this.initialOperations.push({
        type: 'remove',
        context: 'email',
        data: { ...email, person_name: person.name, person_id: person.id },
      })
    }
  }

  storeOriginalEntityValues(client) {
    const initialObject = {
      household: null,
      person: {},
      phone: {},
      email: {},
    }

    return Object.entries(client).reduce((acc, [key, value]) => {
      if (key === 'people') {
        value.forEach((person) => {
          acc.person[person.id] = { id: person.id, name: person.name }
          person.phones.forEach((phone) => {
            acc.phone[phone.id] = { ...phone }
          })
          person.emails.forEach((email) => {
            acc.email[email.id] = { ...email }
          })
        })
        return acc
      }
      acc.household = { ...value }
      return acc
    }, initialObject)
  }

  static createNewClientObject() {
    return {
      household: {
        id_woco: '',
        street: '',
        house_number: '',
        postal_code: '',
        city: '',
        country: '',
      },
      people: [],
    }
  }

  deepCopy(data) {
    if (Array.isArray(data)) {
      return data.map((item) => this.deepCopy(item))
    }
    if (data === null) {
      return data
    }
    if (typeof data === 'object') {
      return Object.entries(data).reduce(
        (acc, [prop, value]) => ({ ...acc, [prop]: this.deepCopy(value) }),
        {}
      )
    }
    return data
  }

  generateAddress(household) {
    return `${household.street} ${household.house_number} ${household.postal_code}`
  }

  generateOperations() {
    const operations = [...this.initialOperations]
    const household = this.client.household
    if (household.id && !isDeepEqual(this.existingEntities.household, household)) {
      operations.push({ type: 'update', context: 'household', data: household })
    }
    if (!household.id) operations.push({ type: 'create', context: 'household', data: household })

    this.client.people.forEach((person) => {
      const personData = { id: person.id, name: person.name }
      if (person.id && !isDeepEqual(personData, this.existingEntities.person[person.id])) {
        operations.push({ type: 'update', context: 'person', data: person })
      }
      if (!person.id) {
        operations.push({ type: 'create', context: 'person', data: person })
      }

      person.phones.forEach((phone) => {
        if (phone.id && !isDeepEqual(phone, this.existingEntities.phone[phone.id])) {
          operations.push({
            type: 'update',
            context: 'phone',
            data: { ...phone, person_id: person.id },
          })
        }
        if (!phone.id) {
          operations.push({
            type: 'create',
            context: 'phone',
            data: { ...phone, person_name: person.name, person_id: person.id },
          })
        }
      })

      person.emails.forEach((email) => {
        if (email.id && !isDeepEqual(email, this.existingEntities.email[email.id])) {
          operations.push({
            type: 'update',
            context: 'email',
            data: { ...email, person_id: person.id },
          })
        }
        if (!email.id) {
          operations.push({
            type: 'create',
            context: 'email',
            data: { ...email, person_name: person.name, person_id: person.id },
          })
        }
      })
    })

    return operations.sort((a, b) => this.sortWeight[b.context] - this.sortWeight[a.context])
  }

  generatePostData() {
    return {
      address: this.generateAddress(this.client.household),
      operations: this.generateOperations(),
    }
  }

  convert() {
    const phonesMap = new WeakMap()
    const phones = this.client.people
      .reduce((acc, person) => [...acc, ...person.phones], [])
      .filter((phone) => {
        if (!phonesMap.has(phone)) {
          phonesMap.set(phone)
          return true
        }
        return false
      })

    return {
      household: this.client.household,
      people: this.client.people, // ?
      phones,
      email: this.client.people.reduce(
        (acc, person) => (acc || !person.emails.length ? acc : person.emails[0]),
        ''
      ),
    }
  }
}
