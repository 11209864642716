<template>
  <table class="relative">
    <thead class="sticky top-0 bg-white">
      <tr class="font-bold text-left bg-blue-50">
        <th class="p-1 w-1/6">Status</th>
        <th class="p-1 w-1/6">Woco ID</th>
        <th class="p-1 w-1/6">Adres</th>
        <th class="p-1 w-1/6">Klantnaam</th>
        <th class="p-1 w-1/6">Email</th>
        <th class="p-1 w-1/6">Telefoon</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(client, index) in clients"
        :key="client.household.id_woco"
        class="border-b border-gray-300"
      >
        <td class="flex p-1">
          <div v-if="result">
            <div
              v-if="result[index]?.household.id_woco === client.household.id_woco"
              class="bg-green-100 rounded-sm p-1 text-xs inline-block"
            >
              {{ action == 'update' ? 'Geüpdatet' : 'Aangemaakt' }}
            </div>
          </div>
          <div v-else-if="duplicates" class="flex flex-col gap-1 p-1 items-start">
            <div v-if="!validation[index].success" class="bg-red-100 rounded-sm p-1 text-xs">
              {{ validation[index].error.issues.length }} validatiefout(en)
            </div>
            <div
              v-if="duplicates[index]?.duplicate && action === 'create'"
              class="bg-red-100 rounded-sm p-1 text-xs"
            >
              Dit adres bestaat al
            </div>
            <div
              v-if="!duplicates[index]?.duplicate && action === 'update'"
              class="bg-red-100 rounded-sm p-1 text-xs"
            >
              Dit adres bestaat nog niet
            </div>
            <div
              v-if="incorrectAddresses[index]?.message"
              class="rounded-sm p-1 text-xs"
              :class="
                incorrectAddresses[index]?.level === 'warning' ? 'bg-yellow-100' : 'bg-red-100'
              "
            >
              {{ incorrectAddresses[index]?.message }}
            </div>
            <div class="bg-red-100 rounded-sm p-1 text-xs" v-if="duplicates[index]?.error">
              {{ duplicates[index]?.error }}
            </div>
          </div>
        </td>
        <td>{{ client.household.id_woco }}</td>
        <td class="text-xs">
          <div v-if="addressError(validation[index])" class="bg-red-100 rounded-sm p-1 text-xs">
            {{ addressError(validation[index]) }}
          </div>

          <div>{{ client.household.street }} {{ client.household.house_number }}</div>
          <div class="has-text-gray">
            {{ client.household.postal_code }} {{ client.household.city }}
          </div>
        </td>
        <td>{{ client.people[0]?.name }}</td>
        <td>
          <div v-if="hasEmailError(validation[index])" class="bg-red-100 rounded-sm p-1 text-xs">
            {{ emailError(validation[index]) }}
          </div>
          <ul v-if="client.people[0]">
            <li v-for="(email, index) in client.people[0].emails" :key="index">
              {{ email.address }}
            </li>
          </ul>
        </td>
        <td>
          <div v-if="hasPhoneError(validation[index])" class="bg-red-100 rounded-sm p-1 text-xs">
            {{ phoneError(validation[index]) }}
          </div>
          <ul v-if="client.people[0]">
            <li v-for="(phone, index) in client.people[0].phones" :key="index">
              {{ phone.number_text }}
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
defineProps({
  clients: Array,
  validation: Array,
  action: String,
  duplicates: Array,
  incorrectAddresses: Array,
  result: Array,
})

function hasEmailError(validation) {
  return !!validation.error?.issues.find((i) => i.path.includes('emails'))
}

function emailError(validation) {
  return validation.error?.issues.find((i) => i.path.includes('emails'))?.message
}

function hasPhoneError(validation) {
  return !!validation.error?.issues.find((i) => i.path.includes('phones'))
}

function phoneError(validation) {
  return validation.error?.issues.find((i) => i.path.includes('phones')).message
}

const addressFields = ['postal_code', 'street', 'house_number', 'city']

function addressError(validation) {
  const issue = validation.error?.issues.find((i) => addressFields.some((f) => i.path.includes(f)))
  if (!issue) return

  return `${issue.path[issue.path.length - 1]}: ${issue.message}`
}
</script>
