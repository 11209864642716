<template>
  <div class="alerts" ref="alertBox" :class="positionClass && positionClass">
    <div
      v-for="alert in alerts"
      :key="alert.message"
      role="alert"
      class="alert"
      :class="{
        'bg-red-100': alert.type === 'danger',
        'bg-green-100': alert.type === 'success',
      }"
      @click="$emit('close')"
    >
      {{ alert.message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    alerts: Array,
    positionClass: String,
  },
}
</script>
