<template>
  <div class="flex flex-start items-center py-5">
    <div
      class="p-8 border-2 border-dashed border-gray-400 rounded-lg max-w-md w-full relative text-center"
      @dragover.prevent="isDragOver = true"
      @dragenter.prevent="isDragOver = true"
      @dragleave.prevent="isDragOver = false"
      @drop.prevent="handleDrop"
    >
      <label
        for="file"
        class="inline-block px-5 py-2 bg-blue-600 text-white rounded cursor-pointer mb-2"
      >
        {{ fileName ? fileName : 'Kies bestand' }}
      </label>
      <input id="file" type="file" class="hidden" @change="handleFileChange" accept=".xls,.xlsx" />
      <div class="mt-2 text-gray-600 font-semibold text-sm">Of sleep het hierheen</div>
      <span class="block mt-5 text-gray-400 text-sm">
        {{ fileName ? fileName : 'Geen bestand gekozen' }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue'

const emit = defineEmits(['fileChange'])

const fileName = ref('')
const isDragOver = ref(false)

const handleDrop = (event) => {
  isDragOver.value = false
  const file = event.dataTransfer.files[0]
  if (file) {
    const fileList = new DataTransfer()
    fileList.items.add(file)
    handleFileChange({ target: { files: fileList.files } })
  }
}

const handleFileChange = (event) => {
  const file = event.target.files[0]
  if (file) {
    fileName.value = file.name
    emit('fileChange', event)
  }
}
</script>
