import { defineStore } from 'pinia'

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    list: [],
  }),
  actions: {
    add(message, type = 'info') {
      console.log('add notification', message, type)
      const id = Math.random().toString(36)
      this.list.push({ id, message, type })

      setTimeout(() => {
        this.remove(id)
      }, 10000)
    },
    remove(id) {
      this.list = this.list.filter((n) => n.id !== id)
    },
  },
})
