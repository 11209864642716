<template>
  <div
    class="status-filter z-50 bg-white"
    ref="statusfilterdiv"
    tabindex="0"
    @blur="(e) => onBlur(e)"
  >
    <div
      v-for="[statusName, count] in options"
      :key="statusName"
      :class="
        'status-filter-option ' +
        `${statusColors[statusName]} ${selectedStatus.includes(statusName) ? '' : 'bolder'} `
      "
      @click="(e) => addToSelection(e, statusName)"
    >
      {{ statusName }} ({{ count }})
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onFilterChange: Function,
    options: Array,
    multiple: Boolean,
  },
  data() {
    return {
      selectedStatus: [],
      statusColors: {
        Installatie: 'bg-green-300',
        Schouw: 'bg-yellow-300',
        Ontwerp: 'bg-gray-300',
        Akkoord: 'bg-blue-300',
      },
    }
  },

  methods: {
    addToSelection(e, value) {
      /*
      if (this.selectedStatus.includes(value)) {
        this.selectedStatus = this.selectedStatus.filter((x) => x !== value);
      } else {
        this.selectedStatus.push(value);
      }
      */
      // TODO: For now, multiple selection is disabled
      if (this.selectedStatus.includes(value)) {
        this.selectedStatus = []
      } else {
        if (this.selectedStatus.length > 1) {
          this.onFilterChange([])
        }
        this.selectedStatus = [value]
      }
      this.onFilterChange(this.selectedStatus)
      this.$emit('blur')
    },
    onBlur(e) {
      this.$emit('blur', e)
    },
  },

  updated() {
    this.$refs.statusfilterdiv.focus()
  },
}
</script>

<style>
.status-filter {
  width: 7rem;
  z-index: 1;
  background-color: white;
  color: black;
  padding: 0.5rem;
  border-radius: 4px;
}

.status-filter:focus {
  outline: 0px solid transparent;
}

.status-filter-option {
  font-weight: normal;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.1rem 0.5rem;
}

.status-filter-option:not(.selected):not(:hover) {
  background-color: white;
  color: black;
  font-weight: bold;
}

.status-filter-option.selected {
  font-weight: bold;
}
</style>
