export default class ClientFormatter {
  constructor(rowData) {
    const [wocoId, address, postalCode, city, country, person, email, phone] = rowData

    const [street, houseNumber] = this.splitAddressField(address)
    const phones = this.splitPhoneField(phone)
    const people = this.splitPersonField(person)

    this.client = {
      household: {
        id_woco: wocoId,
        street,
        house_number: houseNumber,
        postal_code: postalCode.replace(' ', ''),
        city: city.trim(),
        country: country.trim(),
      },
      people: people.map((personName) => this.createPerson(personName, phones, email)),
    }
  }

  createPerson = (personName, phones, email) => ({
    name: personName,
    phones: phones.map((numberText) => ({ number_text: numberText })),
    emails: email?.trim() ? [{ address: email.trim() }] : [],
  })

  splitPhoneField = (phoneField) => {
    let reString = ',(?!\\s?\\d?[^0-9\\s,+(])|' // split on comma if followed by: (multiple digits, comma, space, + or parenthesis)
    reString += ' of |' // split on ' of '
    reString += ' \\| |' // split on ' | '
    reString += '(?<=\\d)\\s?\\/+\\s?(?=\\d)|' // split on forward slashes if surrounded by digits and optionally spaces
    reString += ';|' // split on semi - colons
    reString += '\\d{1}\\.\\s|' // split on single digit followed by a dot
    reString += '(?<=\\d{5}) - (?=\\d{5})' // split on ' - ' if surrounded by at least 5 digits on both sides
    if (!phoneField) return []

    const phones = phoneField.split(new RegExp(reString))
    return phones.reduce((acc, phone) => {
      if (phone.trim()) {
        return [...acc, phone.trim()]
      }
      return acc
    }, [])
  }

  splitAddressField = (addressField) => {
    const re = /^(?<street>.+?)\s+(?<houseNumber>\d+([\s\d\w-]+)*)$/i

    const match = addressField.match(re)

    if (!match || !match.groups.houseNumber) {
      console.warn(`Failed to extract house number from [${addressField}]`)
      return [addressField, null]
    }

    const { street, houseNumber } = match.groups

    return [street.trim(), houseNumber.trim()]
  }

  splitPersonField = (personField) => {
    let reString = '(?:,| of )|' // split on comma and ' of '
    reString += '(?: en )(?!Nijmegen|.*\\/)|' // split on ' en ' if not followed by 'Nijmegen' or '/'
    reString += '(?: & )(?!Vechtstreek|.*\\/)' // split on '&' if not followed by 'Vechtstreek' or '/'
    const re = new RegExp(reString)
    const people = personField.split(re)
    return people.reduce((acc, person) => {
      if (person.trim()) {
        return [...acc, person.trim()]
      }
      return acc
    }, [])
  }
}
