<template>
  <span class="view-image-component">
    <Modal v-if="showImageModal" :onClose="() => (showImageModal = false)">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Akkoordverklaring</h3>
        </div>
        <div class="modal-body image-modal-body">
          <embed
            :src="imageURL"
            type="image/png"
            alt=""
            style="max-height: 100%; max-width: 100%"
          />
        </div>
        <div class="modal-footer">
          <a
            type="button"
            class="btn btn-primary download-image-button"
            download
            :href="imageURL"
            target="_blank"
          >
            <DownloadDocumentIcon />
            Akkoordverklaring downloaden
          </a>
        </div>
      </div>
    </Modal>
  </span>
</template>

<script>
import Modal from '@/components/Modal.vue'
import DownloadDocumentIcon from '@/components/icons/DownloadDocumentIcon.vue'

export default {
  components: {
    Modal,
    DownloadDocumentIcon,
  },
  props: {
    imageURL: String,
    show: Boolean,
  },
  watch: {
    show(bool) {
      this.showImageModal = bool
    },
  },
  data() {
    return {
      showImageModal: this.$props.show,
    }
  },
}
</script>

<style>
.image-modal-body {
  width: 70vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-image-button {
  text-decoration: none;
}
</style>
