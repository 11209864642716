<template>
  <div>
    <div class="m-1 overflow-y-auto overflow-x-hidden wrapper" ref="clientTableWrapper">
      <i style="font-size: 1.1em; padding-bottom: 0.3em" v-if="isDownloading">Downloading...</i>
      <table
        class="mx-auto client-table table-bordered border border-gray-500"
        style="table-layout: fixed; padding-right: 0.25rem"
      >
        <thead class="bg-black text-white sticky">
          <tr style="text-align: left">
            <TableActions
              v-if="hasActions"
              :selectAllRows="selectAllRows"
              :onSelectAllRows="onSelectAllRows"
              :downloadContracts="downloadContracts"
              :downloadAsbuilts="downloadAsbuilts"
              :downloadLetters="downloadLetters"
              :downloadableContracts="downloadableContracts"
              :downloadableAsbuilts="downloadableAsbuilts"
              :downloadableLetters="downloadableLetters"
              :applyTemplates="applyTemplates"
            />
            <th v-else style="width: 70px">Edit</th>
            <!-- -->
            <template v-for="column in columns">
              <template v-if="column.field === 'taskData'">
                <th
                  :key="column.field + '.status'"
                  scope="col"
                  style="width: 125px"
                  class="bg-black"
                >
                  <div class="flex">
                    <div>Status</div>
                    <div>
                      <img
                        src="../assets/sort-white-18dp.svg"
                        title="Sort"
                        style="cursor: pointer"
                        @click="() => (showStatusFilter = !showStatusFilter)"
                      />
                    </div>
                  </div>

                  <Popover v-show="showStatusFilter" containerClass="wrapper">
                    <StatusFilter
                      :onFilterChange="(newStatuses) => onStatusFilterChange(newStatuses)"
                      :options="Object.entries(statusCount)"
                      :multiple="true"
                      @blur="() => (showStatusFilter = false)"
                    />
                  </Popover>
                </th>
                <th class="bg-black" :key="column.field + '.products'" scope="col">
                  <div style="display: flex">
                    Info
                    <i
                      class="fas fa-filter pointer"
                      @click="() => (showInfoFilter = !showInfoFilter)"
                      style="font-size: 0.55rem; margin: 0 3px"
                    ></i>
                    <!-- <FilterIcon
                      @click="() => (showInfoFilter = !showInfoFilter)"
                    /> -->

                    <div>
                      <Popover v-show="showInfoFilter" containerClass="wrapper">
                        <InfoFilter
                          :onFilterChange="
                            (fromDate, toDate, status) =>
                              onInfoFilterChange(fromDate, toDate, status)
                          "
                          :isShown="showInfoFilter"
                          @blur="() => (showInfoFilter = false)"
                        />
                      </Popover>
                    </div>
                  </div>
                </th>
              </template>
              <th
                class="bg-black"
                v-else-if="!column.hide"
                :key="column.field"
                scope="col"
                :style="`width: ${column.width};`"
              >
                {{ column.name }}
                <!-- display button if sortable -->
                <template v-if="column.sortable">
                  <i
                    class="fas"
                    :class="
                      !sortAsc && sortColumn === column.field ? 'fa-chevron-down' : 'fa-chevron-up'
                    "
                    title="Sort"
                    @click="() => selectSortColumn(column.field)"
                    style="cursor: pointer"
                  ></i>
                </template>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <ClientRow
            v-for="(client, index) in filteredClients"
            :key="client.household.id"
            :index="index"
            :columns="columns"
            :hasActions="hasActions"
            :onEditSelection="markClientForEdit"
            :filterLength="minFilterLength"
            :client="client"
            :filter="filterTokens"
            :canEdit="user.permissions.change_data"
            :hasTaskData="hasTaskData"
            :onClickImage="onClickImage"
            :filterStatusHistory="filterStatusHistory"
            :onSelectRow="onSelectRow"
            :onShiftClickRow="onShiftClickRow"
            :setSelectionRange="setSelectionRange"
            :rowSelection="selection"
            :hasHistory="hasHistory"
            :showGroupsColumn="showGroupsColumn"
            :additionalWorkCatalog="additionalWorkCatalog"
            :serviceCatalog="serviceCatalog"
            :asbuiltFilename="
              client.task_data &&
              client.task_data['installation'] &&
              client.task_data['installation'].asbuilt
            "
            :addressPostcode="
              `${client.household.street}_${client.household.house_number}` +
              `_${client.household.postal_code}`
            "
          />
          <tr v-if="filteredClients.length === 0">
            <td colspan="8">Geen adressen gevonden</td>
          </tr>
        </tbody>
      </table>

      <!-- <p v-else class="text-center">No results found.</p> -->
    </div>
  </div>
</template>

<script>
import * as requests from '@/common/requests'
import ClientRow from '@/components/ClientRow.vue'
import InfoFilter from '@/components/InfoFilter.vue'
import TableActions from '@/components/TableActions.vue'
import StatusFilter from '@/components/StatusFilter.vue'
import Popover from '@/components/Popover.vue'
import { mapState } from 'vuex'
import JSZip from 'jszip'

import { escapeRegExpChars } from '@/common/util'

export default {
  name: 'ClientTable',
  components: {
    ClientRow,
    StatusFilter,
    InfoFilter,
    Popover,
    TableActions,
  },
  props: {
    clients: Array,
    markClientForEdit: Function,
    minFilterLength: Number,
    onClickImage: Function,
    columns: Array,
    initialSelectedStatus: String,
    hasHistory: Boolean,
    hasActions: Boolean,
    setAlertMessages: Function,
    setIsFetching: Function,
    serviceCatalog: Object,
    additionalWorkCatalog: Object,
    showGroupsColumn: Boolean,
  },
  data() {
    return {
      filterValue: '',
      filterTokens: {
        street: null,
        houseNumber: null,
        postCode: null,
      },
      sortColumn: undefined,
      sortAsc: true,
      showStatusFilter: false,
      showInfoFilter: false,
      selectedStatus: [],
      resizeEventTimeoutId: null,
      scrollEventTimeoutId: null,
      filterStatusHistory: undefined,
      filterStatuses: [],
      selectAllRows: false,
      selection: [],
      asbuiltsSelection: [],
      showMultipleActions: false,
      actions: {
        downloadContracts: [],
        downloadAsbuilts: [],
        downloadLetters: [],
        applyTemplates: [],
      },
      downloadableContracts: '(0)',
      downloadableAsbuilts: '(0)',
      downloadableLetters: '(0)',
      filterFromDate: undefined,
      filterToDate: undefined,
      isDownloading: false,
      selectionRange: [0, 0],
    }
  },
  computed: {
    ...mapState([
      'user',
      'selectedWoco',
      'batchFilter2021',
      'selectedYear',
      'selectedProject',
      'selectedGroup',
    ]),
    hasTaskData() {
      return this.columns.some((col) => col.field === 'taskData')
    },
    filteredClients() {
      const filteredClients = this.filterClients()
      this.$root.$emit('onFilteredClientsUpdate', filteredClients.length)
      return filteredClients
    },
    statusCount() {
      const options = this.clients.reduce((acc, client) => {
        if (!client.task_data.status_translated) return acc
        if (acc[client.task_data.status_translated] === undefined) {
          acc[client.task_data.status_translated] = 0
        }
        acc[client.task_data.status_translated] += 1
        return acc
      }, {})

      if (!options.Schouw) options.Schouw = 0
      if (!options.Ontwerp) options.Ontwerp = 0
      if (!options.Akkoord && this.clients.some((client) => !!client.task_data.sales)) {
        options.Akkoord = 0
      }

      return options
    },
  },
  watch: {
    selectedWoco() {
      this.clearSelectedRows()
      this.resetFilters()
    },
    selectedProject() {
      this.clearSelectedRows()
      this.resetFilters()
    },
    selectedGroup() {
      this.clearSelectedRows()
      this.resetFilters()
    },
    initialSelectedStatus() {
      if (this.initialSelectedStatus !== undefined) {
        this.selectedStatus = [this.initialSelectedStatus]
      }
    },
    selectAllRows(value) {
      const selectedAddresses = this.filteredClients.map((c) => c.household.address)
      this.$children.forEach((component) => {
        if (
          component.$vnode !== undefined &&
          component.$vnode.componentOptions !== undefined &&
          component.$vnode.componentOptions.tag === 'ClientRow'
        ) {
          if (!value || selectedAddresses.includes(component.$props.client.household.address)) {
            component.onSelectAll(value)
          }
        }
      })
    },
    selection() {
      const selectedRowComponents = this.$children.filter(
        (component) =>
          component.$vnode !== undefined &&
          component.$vnode.componentOptions !== undefined &&
          component.$vnode.componentOptions.tag === 'ClientRow' &&
          this.selection.includes(component.$props.client.household.address)
      )
      const numberOfDownloadableContracts = selectedRowComponents.filter(
        (row) =>
          row.$props.client.task_data.sales !== undefined &&
          row.$props.client.task_data.sales.contract_type !== undefined
      ).length
      this.downloadableContracts = `(${numberOfDownloadableContracts})`

      const numberOfDownloadableAsbuilts = selectedRowComponents.filter(
        (row) =>
          row.$props.client.task_data.installation !== undefined &&
          row.$props.client.task_data.installation.asbuilt !== undefined
      ).length
      this.downloadableAsbuilts = `(${numberOfDownloadableAsbuilts})`

      const numberOfDownloadableLetters = selectedRowComponents.filter(
        (row) => row.$refs.clientDocumentTemplate !== undefined
      ).length
      this.downloadableLetters = `(${numberOfDownloadableLetters})`
    },
  },
  methods: {
    clearSelectedRows() {
      this.selectedStatus = []
      this.actions = {
        downloadContracts: [],
        downloadAsbuilts: [],
        downloadLetters: [],
        applyTemplates: [],
      }
    },
    resetFilters() {
      this.filterStatusHistory = undefined
      this.filtervalue = ''
      this.filterTokens = {
        street: null,
        houseNumber: null,
        postCode: null,
      }
      this.filterFromDate = undefined
      this.filterToDate = undefined
    },
    onSelectAllRows() {
      this.selectAllRows = !this.selectAllRows
    },
    async downloadContracts() {
      this.isDownloading = true

      const selectedRowComponents = this.$children.filter(
        (component) =>
          component.$vnode !== undefined &&
          component.$vnode.componentOptions !== undefined &&
          component.$vnode.componentOptions.tag === 'ClientRow' &&
          this.selection.includes(component.$props.client.household.address)
      )

      const downloadableContractRows = selectedRowComponents.filter(
        (row) =>
          row.$props.client.task_data.sales !== undefined &&
          row.$props.client.task_data.sales.contract_type !== undefined
      )

      if (downloadableContractRows.length < 50) {
        const actions = this.actions.downloadContracts.filter(
          (action) => typeof action === 'function'
        )

        const result = await Promise.all(actions.map((action) => action(false)))

        const files = result.filter((file) => !!file)

        await this.createZipfile(files, 'Akkoordverklaringen')

        this.showMultipleActions = false
      } else if (downloadableContractRows.length > 1000) {
        this.setAlertMessages([
          {
            message: 'U kan slechts een maximum van 1000 akkoordverklaringen per keer downloaden!',
            type: 'danger',
          },
        ])
      } else {
        this.setAlertMessages([
          {
            message:
              'U zal een link per mail ontvangen om het aangevraagde bestand te kunnen downloaden!',
            type: 'warning',
          },
        ])
        const addresses = {}

        downloadableContractRows.forEach((row) => {
          addresses[row.client.household.address] = {
            filename:
              row.client.task_data.sales.contract_type === 'letter'
                ? `${row.client.task_data.sales.letter_contract}`
                : `${row.client.task_data.sales.signrequest_document_uuid}_akkoordverklaring_${row.client.household.address}.pdf`,
            contractType: row.client.task_data.sales.contract_type,
          }
        })
        const jsonData = {
          addresses,
          user: this.user.name,
        }

        await requests.downloadFiles(jsonData, 'contract')
      }

      this.isDownloading = false
    },

    async createZipfile(files, fileType) {
      const zip = new JSZip()
      const folder = zip.folder(fileType)

      files.forEach((file) => {
        folder.file(file.name, file)
      })

      const anchor = document.createElement('a')
      const generatedFolder = await folder.generateAsync({ type: 'blob' })

      const blobDownloadLink = window.URL.createObjectURL(generatedFolder)
      anchor.href = blobDownloadLink

      anchor.download = `${fileType}.zip`
      anchor.click()

      window.URL.revokeObjectURL(blobDownloadLink)
      anchor.remove()
    },

    async downloadAsbuilts() {
      this.$events.emit('interaction', {
        type: 'download',
        context: 'asbuilt',
        value: this.selection.join(', '),
      })

      const selectedRowComponents = this.$children.filter(
        (component) =>
          component.$vnode !== undefined &&
          component.$vnode.componentOptions !== undefined &&
          component.$vnode.componentOptions.tag === 'ClientRow' &&
          this.selection.includes(component.$props.client.household.address)
      )

      const downloadableAsbuiltRows = selectedRowComponents.filter(
        (row) =>
          row.$props.client.task_data.installation !== undefined &&
          row.$props.client.task_data.installation.asbuilt !== undefined
      )

      if (downloadableAsbuiltRows.length < 6) {
        this.actions.downloadAsbuilts.forEach((action) => {
          action()
        })
        this.showMultipleActions = false
      } else if (downloadableAsbuiltRows.length > 500) {
        this.setAlertMessages([
          {
            message: 'U kan slechts een maximum van 500 asbuilts per keer downloaden!',
            type: 'danger',
          },
        ])
      } else {
        this.setAlertMessages([
          {
            message:
              'U zal een link per mail ontvangen om het aangevraagde bestand te kunnen downloaden!',
            type: 'warning',
          },
        ])
        const addresses = {}

        downloadableAsbuiltRows.forEach((row) => {
          addresses[row.client.household.address] = {
            filename: row.asbuiltFilename,
            addressPostcode: row.addressPostcode,
          }
        })
        const jsonData = {
          addresses,
          user: this.user.name,
        }

        await requests.downloadFiles(jsonData, 'asbuilt')
      }
    },

    async downloadLetters() {
      this.$events.emit('interaction', {
        type: 'download',
        context: 'letter',
        value: this.selection.join(', '),
      })

      const selectedRowComponents = this.$children.filter(
        (component) =>
          component.$vnode !== undefined &&
          component.$vnode.componentOptions !== undefined &&
          component.$vnode.componentOptions.tag === 'ClientRow' &&
          this.selection.includes(component.$props.client.household.address)
      )

      const downloadableLetterRows = selectedRowComponents.filter(
        (row) => row.$refs.clientDocumentTemplate !== undefined
      )

      if (downloadableLetterRows.length < 50) {
        const actions = this.actions.downloadLetters.filter(
          (action) => typeof action === 'function'
        )

        const result = await Promise.all(actions.map((action) => action(false)))

        const files = result.filter((file) => !!file)

        await this.createZipfile(files, 'Letters')
        this.showMultipleActions = false
      } else if (downloadableLetterRows.length > 1000) {
        this.setAlertMessages([
          {
            message: 'U kan slechts een maximum van 100 brieven per keer downloaden!',
            type: 'danger',
          },
        ])
      } else {
        this.setAlertMessages([
          {
            message:
              'U zal een link per mail ontvangen om het aangevraagde bestand te kunnen downloaden!',
            type: 'warning',
          },
        ])
        const addresses = {}
        downloadableLetterRows.forEach((row) => {
          addresses[row.client.household.address] = {
            filename: `${row.client.household.address}.doc`,
            client: JSON.parse(JSON.stringify(row.client)),
            template: row.$refs.clientDocumentTemplate.selectedTemplate.name,
          }
          addresses[row.client.household.address].client.people[0].name = addresses[
            row.client.household.address
          ].client.people[0].name.replace('De heer of mevrouw ', '')
        })
        const jsonData = {
          addresses,
          user: this.user.name,
          woco: this.selectedWoco,
        }

        await requests.downloadFiles(jsonData, 'letter')
      }
    },

    applyTemplates(template) {
      this.$events.emit('interaction', {
        type: 'click',
        context: 'apply_letter_template',
        value: `template=${template.name}`,
      })
      this.actions.applyTemplates.forEach((action) => action(template))
      this.showMultipleActions = false
    },

    onSelectRow(address, contract, asbuilt, letter, template) {
      if (this.selection.includes(address)) {
        const addressIndex = this.selection.indexOf(address)
        this.selection.splice(addressIndex, 1)
        this.actions.downloadContracts.splice(addressIndex, 1)
        this.actions.downloadAsbuilts.splice(addressIndex, 1)
        this.actions.downloadLetters.splice(addressIndex, 1)
        this.actions.applyTemplates.splice(addressIndex, 1)
      } else {
        this.selection.push(address)
        this.actions.downloadContracts.push(contract)
        this.actions.downloadAsbuilts.push(asbuilt)
        this.actions.downloadLetters.push(letter)
        this.actions.applyTemplates.push(template)
      }
    },

    onInfoFilterChange(fromDateString, toDateString, statuses) {
      this.clearSelection()
      this.filterFromDate = new Date(fromDateString)
      if (Number.isNaN(this.filterFromDate.valueOf())) {
        this.filterFromDate = undefined
      }
      this.filterToDate = new Date(toDateString)
      if (Number.isNaN(this.filterToDate.valueOf())) {
        this.filterToDate = undefined
      }
      this.filterStatuses = statuses
    },

    onStatusFilterChange(newStatus) {
      this.clearSelection()
      const statusTranslationBack = {
        Installatie: 'installation',
        Schouw: 'inspection',
        Ontwerp: 'design',
        Akkoord: 'sales',
      }
      this.$events.emit('interaction', {
        type: 'filter',
        context: 'status',
        value: `woco=${this.selectedWoco}&status=${newStatus}`,
      })
      this.selectedStatus = newStatus
      this.filterStatusHistory = statusTranslationBack[this.selectedStatus[0]]
    },
    onFilterUpdate(filterValue) {
      setTimeout(() => {
        this.clearSelection()
        this.filterValue = filterValue
        this.setFilterTokens(filterValue)
      }, 100)
    },
    selectSortColumn(field) {
      if (this.sortColumn === field) {
        this.sortAsc = !this.sortAsc
      } else {
        this.sortColumn = field
      }
      this.sortClients()
    },
    sortClients() {
      if (this.sortColumn === undefined) {
        return
      }

      this.$events.emit('interaction', {
        type: 'sort',
        context: 'clients',
        value: [
          `woco=${this.selectedWoco}`,
          `&column=${this.sortColumn.split('.').slice(-1)}`,
          `&direction=${this.sortAsc ? 'asc' : 'desc'}`,
        ].join(''),
      })

      const fieldKeys = this.sortColumn.split('.')

      this.clients.sort((a, b) => {
        const valueA = fieldKeys.reduce((acc, k) => acc[k], a)
        const valueB = fieldKeys.reduce((acc, k) => acc[k], b)

        if (fieldKeys[1] === 'address') {
          const numberA = Number(a.household.house_number.split(/[^0-9]/)[0])
          const numberB = Number(b.household.house_number.split(/[^0-9]/)[0])
          const streetA = a.household.street
          const streetB = b.household.street

          if (streetA !== streetB) {
            return this.sortAsc ? streetA.localeCompare(streetB) : streetB.localeCompare(streetA)
          }
          if (streetA === streetB) return this.sortAsc ? numberA - numberB : numberB - numberA
        }

        if (typeof valueA === 'string') {
          return this.sortAsc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
        }
        return this.sortAsc ? valueA - valueB : valueB - valueA
      })
    },
    setFilterTokens(input) {
      let tokens = {
        street: null,
        houseNumber: null,
        postCode: null,
      }

      let regexp = new RegExp(
        [
          // get ^<street> <house_no>? <postal_code>?
          '^(?<street>\\d{0,1}\\s*[a-z.()\\/\\-]+(\\s*[a-z.()\\/\\-]+)*){1}',
          '\\s*(?<houseNumber>\\d+(\\s*-?[a-z0-9]{1,3})?(?=\\s+\\d{4})|\\d+(\\s*-?[a-z0-9]{1,3})?){0,1}',
          '\\s*(?<postCode>\\d{4}(\\s*[a-z]{1,2})?){0,1}',
        ].join(''),
        'i'
      )
      let match = input.match(regexp)

      if (!match) {
        regexp = new RegExp(
          [
            // get ^<postal_code> <house_no>?
            '^(?<postCode>(\\d{4}(\\s*[a-z]{1,2}){0,1}|\\d{1,4})){1}',
            '\\s*(?<houseNumber>\\d+(\\s*-?[a-z0-9]{1,3})?){0,1}',
          ].join(''),
          'i'
        )
        match = input.match(regexp)
      }

      tokens = match ? { ...tokens, ...match.groups } : tokens
      tokens = Object.entries(tokens).reduce((acc, [key, value]) => {
        // convert tokens into regexps
        if (key === 'postCode' && value && value.length > 4) {
          const v = value.replace(/\s/g, '')
          acc[key] = new RegExp(`^${v.slice(0, 4)}\\s*${v.slice(4)}`, 'i')
        } else if (key === 'houseNumber' && value) {
          acc[key] = new RegExp(`^${value.replace(/[\s-]/g, '(\\s{1}|-{1})')}`, 'i')
        } else if (key === 'street' && value) {
          acc[key] = new RegExp(`${escapeRegExpChars(value)}`, 'i')
        } else {
          acc[key] = value ? new RegExp(`^${escapeRegExpChars(value)}`, 'i') : value
        }
        return acc
      }, tokens)

      this.filterTokens = tokens
    },
    clientMatchesInstallationDate(c) {
      if (
        c.task_data === undefined ||
        c.task_data.installation === undefined ||
        c.task_data.installation.appointment_date === undefined
      )
        return false

      if (c.task_data.installation.appointment_date !== undefined) {
        // if (
        //   c.task_data.installation.status === undefined
        //   || !['gecontroleerd', 'gebouwd'].includes(c.task_data.installation.status)
        // ) return false;

        const cDate = new Date(c.task_data.installation.appointment_date)
        if (this.filterFromDate !== undefined && this.filterToDate !== undefined) {
          return this.filterFromDate <= cDate && cDate <= this.filterToDate
        }
        if (this.filterToDate !== undefined) {
          return cDate <= this.filterToDate
        }
        return this.filterFromDate <= cDate
      }
      return false
    },
    clientMatchesFilterTokens(client, tokens) {
      const getClientValueFromToken = {
        street: client.household.street,
        houseNumber: client.household.house_number,
        postCode: client.household.postal_code,
      }

      return !tokens.some(([tokenName, regexp]) =>
        regexp ? !regexp.test(getClientValueFromToken[tokenName]) : false
      )
    },
    shouldShowStatus(statusTranslated) {
      if (this.selectedStatus.includes(statusTranslated)) {
        return true
      }
      if (statusTranslated === 'Installatie') {
        return (
          this.selectedStatus.includes('Akkoord') ||
          this.selectedStatus.includes('Ontwerp') ||
          this.selectedStatus.includes('Schouw')
        )
      }
      if (statusTranslated === 'Schouw') {
        return this.selectedStatus.includes('Akkoord') || this.selectedStatus.includes('Ontwerp')
      }
      if (statusTranslated === 'Akkoord') {
        return this.selectedStatus.includes('Ontwerp')
      }
      return false
    },
    filterClients() {
      if (
        !this.filterValue &&
        !this.selectedStatus.length &&
        this.filterFromDate === undefined &&
        this.filterToDate === undefined &&
        !this.filterStatuses.length
      )
        return this.clients

      if (this.filterFromDate !== undefined || this.filterToDate !== undefined) {
        this.$events.emit('interaction', {
          type: 'filter',
          context: 'installation_date',
          value: `from: ${this.filterToDate}, to: ${this.filterFromDate}`,
        })
      }

      const tokenItems = Object.entries(this.filterTokens)

      const statusTranslation = {
        Ontwerp: 'design',
        Schouw: 'inspection',
        Installatie: 'installation',
        Akkoord: 'sales',
      }

      const filteredClients = this.clients.filter((client) => {
        if (
          // filter on text filter
          this.filterValue &&
          !this.clientMatchesFilterTokens(client, tokenItems)
        )
          return false
        if (
          // filter on task status
          this.selectedStatus.length &&
          !this.shouldShowStatus(client.task_data.status_translated)
        )
          return false
        if (
          // filter on installation date
          (this.filterFromDate !== undefined || this.filterToDate !== undefined) &&
          !this.clientMatchesInstallationDate(client)
        )
          return false
        if (this.filterStatuses.length > 0) {
          if (this.selectedStatus.length > 0) {
            return this.filterStatuses.includes(
              client.task_data[statusTranslation[this.selectedStatus[0]]].status
            )
          }
          return this.filterStatuses.includes(client.task_data[client.task_data.status].status)
        }

        return true
      })

      return filteredClients
    },
    setTableHeight() {
      const viewHeight = window.innerHeight
      const bodyRect = document.body.getBoundingClientRect()
      const wrapperRect = this.$refs.clientTableWrapper.getBoundingClientRect()
      const offsetTop = bodyRect.top + wrapperRect.top
      const offsetBottom = bodyRect.bottom - wrapperRect.bottom
      const height = viewHeight - (offsetTop + offsetBottom)
      this.$refs.clientTableWrapper.style.maxHeight = `${height}px`
    },
    onWindowResize() {
      clearTimeout(this.resizeEventTimeoutId)
      this.resizeEventTimeoutId = setTimeout(() => {
        this.setTableHeight()
        this.$events.emit('window-resize', null)
      }, 100)
    },
    onScrollTable() {
      if (!this.scrollEventTimeoutId) {
        this.$events.emit('scroll-start', 'table')
      }
      clearTimeout(this.scrollEventTimeoutId)
      this.scrollEventTimeoutId = setTimeout(() => {
        this.scrollEventTimeoutId = null
        this.$events.emit('scroll-end', 'table')
      }, 500)
    },
    clearSelection() {
      if (this.selectAllRows) {
        this.selectAllRows = false
      } else {
        this.$children.forEach((component) => {
          if (
            component.$vnode !== undefined &&
            component.$vnode.componentOptions !== undefined &&
            component.$vnode.componentOptions.tag === 'ClientRow'
          ) {
            component.onSelectAll(false)
          }
        })
      }
    },
    setSelectionRange(rowIndex) {
      this.selectionRange = [rowIndex, rowIndex]
    },
    onShiftClickRow(rowIndex) {
      let [start, end] = this.selectionRange
      if (rowIndex < start) this.selectionRange = [rowIndex, end]
      if (rowIndex > end) this.selectionRange = [start, rowIndex]

      const selectedAddresses = this.filteredClients.map((c) => c.household.address)
      ;[start, end] = this.selectionRange
      this.$children.forEach((component) => {
        if (
          component.$vnode !== undefined &&
          component.$vnode.componentOptions !== undefined &&
          component.$vnode.componentOptions.tag === 'ClientRow'
        ) {
          if (
            selectedAddresses.includes(component.$props.client.household.address) &&
            component.$props.index >= start &&
            component.$props.index <= end
          ) {
            component.onSelectAll(true)
          }
        }
      })
    },
  },
  mounted() {
    this.setTableHeight()
    this.$refs.clientTableWrapper.addEventListener('scroll', this.onScrollTable)
    window.addEventListener('resize', this.onWindowResize)
    this.$root.$on('onFilterUpdate', this.onFilterUpdate)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize)
    this.$refs.clientTableWrapper.removeEventListener('scroll', this.onScrollTable)
    this.$root.$off('onFilterUpdate', this.onFilterUpdate)
  },
}
</script>

<style type="css">
.table-bordered td {
  border: 1px solid #dee2e6;
}
</style>
