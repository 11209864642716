<template>
  <span class="date-badge" :style="`background-color: ${badgeColor};`">{{ label }}</span>
</template>

<script>
export default {
  name: 'DateBadge',
  props: {
    label: String,
    badgeColor: String,
  },
}
</script>

<style>
.date-badge {
  border-radius: 4px;
  padding: 0.1rem 0.25rem;
  font-size: 0.6rem;
  color: whitesmoke;
  margin-right: 5px;
}
</style>
