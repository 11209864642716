<template>
  <div class="wrapper">
    <!-- <label for="wocos">Select a woco:</label> -->
    <select name="years" class="text-black bg-gray-200 p-1 rounded" id="years" @change="onChange">
      <option v-for="year in years" :key="year" :value="year" :selected="selectedYear === year">
        {{ year }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'DropDown',
  computed: {
    ...mapState(['selectedYear', 'selectedWoco', 'user']),
    years() {
      if (this.selectedWoco === null || this.selectedWoco === undefined) {
        return []
      }
      const woco = this.user.wocos.find((w) => w.name === this.selectedWoco)

      if (woco === undefined) {
        return []
      }

      return Object.keys(woco.available_years)
    },
  },
  methods: {
    ...mapMutations(['setSelectedYear']),
    onChange({ target }) {
      this.setSelectedYear(target.value)
      this.$events.emit('interaction', {
        type: 'select',
        context: 'dropdown-year',
        value: this.selectedYear,
      })
    },
  },
  mounted() {
    if (this.years.length === 1) {
      this.setSelectedYear(this.years[0])
    }
  },
}
</script>
