<template>
  <div class="container">
    <hr />
    <div class="flex flex-row gap-2 my-2">
      <button type="button" class="rounded bg-blue-400 p-2" @click="handler.addEmail(person)">
        Voeg email toe
      </button>
      <button type="button" class="rounded bg-blue-400 p-2 mr-2" @click="handler.addPhone(person)">
        Voeg tel. nummer toe
      </button>
      <button
        type="button"
        class="rounded bg-red-400 p-2 ml-auto"
        @click="handler.removePerson(person)"
      >
        Verwijder persoon van adres
      </button>
    </div>
    <div class="flex flex-row gap-2">
      <div class="flex flex-col gap-2 grow">
        <EditClientField
          :id="`person_name${unique}`"
          label="Naam"
          required
          :modelValue="person.name"
          @update:modelValue="person.name = $event.target.value"
        />
        <EditClientField
          :id="`person_emails${unique}`"
          label="Email(s)"
          required
          :modelValue="person.emails.map((e) => e.address)"
          @update:modelValue="(index, e) => (person.emails[index].address = e.target.value)"
          :onRemove="(index) => handler.removeEmail(person, person.emails[index])"
        />
      </div>
      <div class="flex flex-row gap-2 grow">
        <EditClientField
          :id="`person_phones${unique}`"
          label="Telefoon nummer(s)"
          required
          :modelValue="person.phones.map((p) => p.number_text)"
          @update:modelValue="(index, e) => (person.phones[index].number_text = e.target.value)"
          :onRemove="(index) => handler.removePhone(person, person.phones[index])"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import EditClientField from './EditClientField.vue'

const unique = ref(null)

defineProps({
  handler: Object,
  person: Object,
})

onBeforeMount(() => (unique.value = String(Math.random()).substring(0, 10)))
</script>
