<template>
  <span
    v-if="label !== undefined && label.length > 0"
    class="status-badge"
    :style="`background-color: ${labelColors[label]}; color: ${textColor}`"
    v-html="label"
  >
  </span>
</template>

<script>
export default {
  name: 'StatusBadge',
  props: {
    label: String,
  },
  data() {
    return {
      labelColors: {
        approved: 'darkseagreen',
        assigned: 'skyblue',
        finished: 'darkseagreen',
        cancelled: 'indianred',
        ongoing: 'gold',
        created: 'skyblue',
        rejected: 'indianred',
      },
      // labelColors: {
      //   aangemeld: 'skyblue',
      //   afgemeld: 'indianred',
      //   'in proces': 'gold',
      //   ontwerp: 'lightgrey',
      //   geannuleerd: 'indianred',
      //   'nog niet bereikt': 'gold',
      //   akkoord: 'darkseagreen',
      //   'in afwachting': 'indianred',
      //   gebouwd: 'lightgrey',
      //   gecontroleerd: 'darkseagreen',
      //   gepland: 'skyblue',
      //   'te plannen': 'gold',
      // },
    }
  },
  computed: {
    textColor() {
      return ['in proces', 'nog niet bereikt', 'te plannen'].includes(this.label)
        ? 'dimgray'
        : 'whitesmoke'
    },
  },
}
</script>

<style>
.status-badge {
  border-radius: 4px;
  padding: 0.1rem 0.25rem;
  font-size: 0.6rem;
  margin-right: 5px;
}
</style>
