<template>
  <div ref="popoverdiv" class="popover" tabindex="0" @blur="(e) => onBlur(e)">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    offset: Object,
    align: Object,
    target: HTMLElement,
    containerClass: String,
  },
  data() {
    return {
      offsetY: this.offset && this.offset.y ? this.offset.y : 0,
      offsetX: this.offset && this.offset.x ? this.offset.x : 0,
      alignment: {
        x: this.align && this.align.x ? this.align.x : 'left',
        y: this.align && this.align.y ? this.align.y : 'top',
      },
      boundingBox: null,
    }
  },
  methods: {
    onBlur(e) {
      this.$emit('blur', e)
    },
    setBoundingBox() {
      const documentBody = document.body
      if (!this.containerClass) {
        this.boundingBox = documentBody
        return
      }

      let parentNode = this.$refs.popoverdiv.parentNode
      while (parentNode !== documentBody) {
        if (parentNode.classList.contains(this.containerClass)) {
          this.boundingBox = parentNode
          return
        }
        parentNode = parentNode.parentNode
      }
      console.warn(`Failed to find container class [${this.containerClass}] for popover`)
      console.warn('Using document.body as default')
      this.boundingBox = document.body
    },
    getCenteringOffset(targetRect, popoverRect) {
      const offset = { x: 0, y: 0 }
      if (targetRect.width > popoverRect.width) {
        offset.x = -((targetRect.width - popoverRect.width) / 2)
      }
      if (targetRect.width < popoverRect.width) {
        offset.x = (popoverRect.width - targetRect.width) / 2
      }
      if (targetRect.height > popoverRect.height) {
        offset.y = -((targetRect.height - popoverRect.width) / 2)
      }
      if (targetRect.height < popoverRect.height) {
        offset.y = (popoverRect.height - targetRect.height) / 2
      }
      return offset
    },
    getPositionalData() {
      const viewHeight = window.innerHeight
      const viewWidth = window.innerWidth
      const popover = this.$refs.popoverdiv
      const popoverRect = popover.getBoundingClientRect()
      const targetNode = this.target ? this.target : popover.parentNode
      const targetRect = targetNode.getBoundingClientRect()
      const boundingBoxRect = this.boundingBox.getBoundingClientRect()

      const centerOffset = this.getCenteringOffset(targetRect, popoverRect)

      const positions = {
        top: targetRect.top + targetRect.height + this.offsetY,
        bottom: viewHeight - targetRect.bottom + (targetRect.height + this.offsetY),
        left: targetRect.left + this.offsetX,
        right: viewWidth - targetRect.right + this.offsetX,
        centerX: targetRect.left - centerOffset.x,
        centerY: targetRect.top - centerOffset.y,
      }

      const exceedsBoundary = {
        top: positions.top + popoverRect.height > boundingBoxRect.bottom,
        bottom: positions.bottom + popoverRect.height + boundingBoxRect.top > viewHeight,
        left: positions.left + popoverRect.width > boundingBoxRect.right,
        right: positions.right + popoverRect.width + boundingBoxRect.left > viewWidth,
        centerX:
          positions.centerX + popoverRect.width > boundingBoxRect.right ||
          positions.centerX < boundingBoxRect.left,
        centerY:
          positions.centerY + popoverRect.height > boundingBoxRect.top ||
          positions.centerY < boundingBoxRect.bottom,
      }

      return { positions, exceedsBoundary }
    },
    setPosition() {
      const { positions, exceedsBoundary } = this.getPositionalData()
      const invert = {
        top: 'bottom',
        bottom: 'top',
        right: 'left',
        left: 'right',
      }
      const attributeFromCenterKey = { centerX: 'left', centerY: 'top' }

      for (let idx = 0; idx < 2; idx += 1) {
        // iterate for horizontal and vertical
        const axis = idx === 0 ? 'x' : 'y'
        const centerKey = `center${axis.toUpperCase()}`
        const positionKey = this.alignment[axis] === 'center' ? centerKey : this.alignment[axis]

        let positionValue
        let attr

        if (!exceedsBoundary[positionKey]) {
          // use preferred alignment
          attr = positionKey === centerKey ? attributeFromCenterKey[centerKey] : positionKey
          positionValue = positions[positionKey]
        } else {
          // use non-exceeding or preferred alignment if they all go out of bounds
          const options = axis === 'x' ? [centerKey, 'right', 'left'] : [centerKey, 'bottom', 'top']
          options.forEach((key) => {
            // look for fitting alignment option
            if (!exceedsBoundary[key]) {
              positionValue = positions[key]
              attr = key === centerKey ? attributeFromCenterKey[centerKey] : key
            }
          }) // use preferred alignment if none of the options fit
          if (!positionValue && !attr) {
            positionValue = positions[positionKey]
            attr = positionKey === centerKey ? attributeFromCenterKey[centerKey] : positionKey
          }
        }
        this.$refs.popoverdiv.style[attr] = `${positionValue}px`
        this.$refs.popoverdiv.style[invert[attr]] = 'auto'
      }
    },
  },
  mounted() {
    this.setBoundingBox()
    this.setPosition()
    this.$events.on('scroll-end', this.setPosition)
    this.$events.on('window-resize', this.setPosition)
    if (document.activeElement.name !== 'filterValue') {
      this.$refs.popoverdiv.focus()
    }
  },
  updated() {
    this.setPosition()
  },
  beforeDestroy() {
    this.$events.off('scroll-end', this.setPosition)
    this.$events.off('window-resize', this.setPosition)
  },
}
</script>

<style>
.popover {
  position: fixed;
  bottom: auto;
  top: auto;
  left: auto;
  right: auto;
  z-index: 1;
  background-color: white;
  color: black;
  padding: 0.5rem;
  border-radius: 4px;
  width: max-content;
  font-size: inherit;
  box-shadow:
    0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.popover:focus {
  outline: 0px solid transparent;
}
</style>
