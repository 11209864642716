<template>
  <div>
    <AppHeader :selectedScreen="$route.name" :enabled="navigationEnabled" />
    <div class="relative">
      <div class="absolute z-10 right-0 top-0 mt-3 mr-3">
        <div class="flex flex-col gap-2">
          <NotificationMessage
            v-for="notification in notifications.list"
            :key="notification.id"
            :message="notification.message"
            :type="notification.type"
            @close="notifications.remove(notification.id)"
          />
        </div>
      </div>
      <router-view @loginChange="verifyStatus" @toggleNavigation="toggleNavigation" />
    </div>
  </div>
</template>

<script setup>
import * as auth from '@/common/auth'
import AppHeader from '@/components/AppHeader.vue'
import { useNotificationsStore } from '@/store/notifications'
import { ref } from 'vue'
import './assets/scss/index.css'
import NotificationMessage from './components/NotificationMessage.vue'

const notifications = useNotificationsStore()
console.log(notifications.list)

const navigationEnabled = ref(true)

const verifyStatus = () => {
  auth.verifyUserLoginStatus()
}

const toggleNavigation = (enabled) => {
  navigationEnabled.value = enabled
}
</script>

<style lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
