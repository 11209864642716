<template>
  <div class="otp-input">
    <form class="mb-4">
      <input v-model="otp" placeholder="OTP code" class="p-2 rounded-l" />
      <button
        type="button"
        class="bg-ecorus w-full h-full p-2 rounded-r text-white"
        @click="() => onSubmit(otp)"
      >
        Login
      </button>
    </form>
    <p style="font-size: 15px">
      Werkt de code niet? Vraag
      <a href="/" @click.prevent="requestCode"> hier een nieuwe aan</a>.
      <!-- Code not working? Request <a href="/" @click.prevent="requestCode">new code here</a>. -->
    </p>
  </div>
</template>

<script>
export default {
  name: 'OTPInput',
  props: {
    onSubmit: Function,
    requestCode: Function,
  },
  data() {
    return {
      otp: '',
    }
  },
}
</script>

<style lang="postcss" scoped>
.otp-input {
  form {
    display: flex;
    flex-direction: row;
  }
}
</style>
