<template>
  <span class="view-pdf-component">
    <Modal v-if="showPDFModal" :onClose="() => (showPDFModal = false)">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">{{ modalTitle }}</h3>
        </div>
        <div class="modal-body pdf-modal-body">
          <embed :src="pdfURL" width="100%" height="100%" />
        </div>
        <div class="modal-footer"></div>
      </div>
    </Modal>
  </span>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  components: {
    Modal,
  },
  props: {
    pdfURL: String,
    show: Boolean,
    modalTitle: String,
  },
  watch: {
    show(bool) {
      this.showPDFModal = bool
    },
  },
  data() {
    return {
      showPDFModal: this.$props.show,
    }
  },
}
</script>

<style>
.pdf-modal-body {
  width: 70vw;
  height: 80vh;
}

.download-pdf-button {
  text-decoration: none;
}
</style>
